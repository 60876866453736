/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// import defaultImage from "../../assets/loader.gif"
import loaderVid from '../../assets/loader.webm'

// import Client from '../../client';
// import Cookies from 'universal-cookie';
import { css } from './css';
// import AuthContext from '../../context/auth-context';
import Hls from 'hls.js';

const VideoPlayer = ({
    url, 
    setStatus,
    style,
    fullScreen = false
 }) => {
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(true);

  const videoRef = useRef();
  const resetTimeout = useRef();

  const RESET_INTERVAL = 3000;
  // const {checkToken} = useContext(AuthContext);
  
  useEffect(() => {
    if(fullScreen && videoRef?.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) { 
        videoRef.current.msRequestFullscreen();
      }
    }
  }, [fullScreen])

  useEffect(() => {
    // do nothing if no url is set
    if (!url || !init) {
      setInit(true);
      return;
    }

    // start hls
    let hls = null;

    async function startVideo() {
      try {
        if (!Hls.isSupported()) {
          if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            // since it's not possible to detect timeout errors in iOS,
            // wait for the playlist to be available before starting the stream
            await fetch(url);

            videoRef.current.src = url;
          } else {
            return;
          }
        }

        hls = new Hls({
          enableWorker: true,
          maxBufferLength: 1,
          liveBackBufferLength: 0,
          liveSyncDuration: 1,
          liveMaxLatencyDuration: 5,
          liveDurationInfinity: true,
          highBufferWatchdogPeriod: 1,
        });

        hls.on(Hls.Events.ERROR, (evt, data) => {
          if (!data.fatal) return;

          hls.destroy();
          console.log("fatal error", data);
        });

        hls.on(Hls.Events.MANIFEST_LOADED, () => {
          setLoading(false)
          if(videoRef && videoRef.current){videoRef.current.style.display = "block"; videoRef.current.style.width = "100%"}
          setStatus("");
        });

        hls.on(Hls.Events.FRAG_LOADED, () => {
          clearTimeout(resetTimeout.current);
          resetTimeout.current = setTimeout(() => {
          }, RESET_INTERVAL);
        });

        hls.loadSource(url);
        hls.attachMedia(videoRef.current);
      } catch (err) {
        setStatus("Camera connection failed");
        console.error(err)
      }
    }

    startVideo();

    return () => {
      hls?.destroy();
    };
  }, [url, setStatus, init])

  const loader = (
    <div css={css.loader}>
      {/* <img
        src={defaultImage}
        alt="loading"
        css={css.loader.img}
      /> */}
      <video autoPlay muted playsInline loop>
        <source src={loaderVid} type="video/webm" />
      </video>
    </div>
  );

  return (
    <div css={css.container(false, loading)} style={style}>
      {loading && loader}
      <video
        ref={videoRef}
        className={`${css.viewer}`}
        muted
        autoPlay
        playsInline
      />
    </div>
  );
};

VideoPlayer.props = {
  url: PropTypes.string.isRequired
};

export default VideoPlayer;