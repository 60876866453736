import {
  dimensions,
  flexbox,
  padding,
  posFixed
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'

export const css = {
  header: {
    ...flexbox('row', 'center', 'space-between', 32),
    ...padding(8, 8, 8, 24),
    ...posFixed(0, 0, 0, 0, 100),
    backgroundColor: appColors?.greens?.base,
    height: 60,

    '@media screen and (min-width: 600px)': {
      gap: 100,
      paddingLeft: 24,
      paddingRight: 30,
    },
  },

  logo: {
    maxWidth: 166,

    '> svg': {
      ...dimensions(145, 'auto'),
      display: 'block',

      '@media screen and (min-width: 600px)': {
        ...dimensions(166, 60),
      },
    },
  },
}
