import {
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  posRelative,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  dialogHeader: {
    ...fontbox(appFonts?.inter, 18, 700, 16),
  },

  feedback: {
    ...fontbox(appFonts?.inter, 12, 400, 12),
    color: appColors.reds.base,
    marginTop: -20,
    marginBottom: 8,
  },

  container: {
    ...flexbox('column', 'flex-start', 'flex-start', 2),
    ...fullWidth(),

    '> *': {
      ...fullWidth(),
      ...posRelative(),
    },

    labelBlock: {
      ...flexbox('column', 'flex-start', 'flex-start'),

      label: {
        ...fontbox(appFonts?.inter, 14, 700, 24),
        color: appColors?.grays[700],

        '> span': {
          color: appColors?.reds?.base,
        },
      },
    },

    '& .dropdown': {
      ...borderRadius(4),
      ...fontbox(appFonts?.inter, 14, 400, 24),
      ...fullWidth(),
      border: `1px solid ${appColors?.grays?.tabActive}`,
      boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
      color: appColors?.grays?.[900],

      '> span': {
        ...paddingX(12),
      },
    },
  },
}
