/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { Button as PrButton } from "primereact/button";

import { css } from "./css";

const Button = (props) => {
  const type = props?.type || "button";
  const isLink = props?.link || false;
  const color = props?.color || "primary";
  const outlined = props?.variant === "outlined";
  const isTableBtn = props?.isTableBtn || false;

  const theme = useTheme();

  const {isTableBtn: _, ...mostProps} = props
  const cssContainer = {
    ...css(theme, color, outlined, isTableBtn).container,
    ...(isLink && css(theme, color).container?.link),
  };

  return <PrButton unstyled outlined={outlined} type={type} css={cssContainer} {...mostProps} />;
};

export default Button;
