import { flexbox, fullWidth, marginAuto } from "../../emotion/utils";

export const css = {
  ...flexbox("column", "flex-start", "flex-start", 12),
  ...fullWidth(),
  ...marginAuto(),
  maxWidth: "94%",
  paddingTop: 10,
  paddingBottom: 35,

  ".tableContain": {
    ...fullWidth(),
    marginTop: -13,

    '@media screen and (min-width: 600px)': {
      marginTop: 0,
    },
  },
};
