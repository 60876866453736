import {
  borderRadius,
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
  themes,
} from '../../emotion/utils'

import { appFonts } from '../../emotion/appFonts'

export const cssFormElements = {
  ...flexbox('column', 'flex-start', 'flex-start', 2),

  '> *, input': {
    ...fullWidth(),
  },

  'label': {
    ...fontbox(appFonts?.inter, 14, 700, 20),
    color: themes?.light?.colors?.text?.secondary,
    textAlign: 'left',
    textWrap: 'nowrap',
  },

  input: {
    ...borderRadius(8),
    ...fontbox(appFonts?.inter, 16, 400, 24),
    ...paddingX(14),
    ...paddingY(10),
    backgroundColor: themes?.light?.colors?.background?.primary,
    border: `1px solid ${themes?.light?.colors?.border?.primary}`,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    color: themes?.light?.colors?.placeholder,
  },

  textarea: {
    ...borderRadius(8),
    ...fontbox(appFonts?.inter, 16, 400, 24),
    ...paddingX(14),
    ...paddingY(10),
    border: `1px solid ${themes?.light?.colors?.border?.primary}`,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    color: themes?.light?.colors?.placeholder,
  },

  '.p-password': {
    '.p-icon-field': {
      ...fullWidth(),
    },
  },
}
