import { object, string } from 'yup';

const setPasswordSchema = object({
  password: string().label("Password").min(6).required(),
  confirmPassword: string()
    .label("Confirm Password")
    .test(
      'passwords-match',
      ({ label }) => `${label} matches`,
      (value, { schema }) => value === schema.password
    )
});

export default setPasswordSchema;