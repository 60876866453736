import { appFonts } from '../../emotion/appFonts'
import {
  borderRadius,
  darken,
  fontbox,
  paddingX,
  paddingY,
  transition,
} from '../../emotion/utils'

export const css = (theme, color, outlined, isTableBtn) => ({
  container: {
    ...borderRadius(8),
    ...fontbox(
      appFonts?.inter,
      isTableBtn ? 10 : 12,
      isTableBtn ? 500 : 700,
      isTableBtn ? 16 : 20,
    ),
    ...paddingX(isTableBtn ? 8 : 16),
    ...paddingY(isTableBtn ? 4 : 10),
    ...transition(),
    backgroundColor: outlined
      ? 'transparent'
      : theme?.button?.[color]?.background ||
        theme?.button?.primary?.background,
    border: `1px solid ${
      theme?.button?.[color]?.border || theme?.button?.primary?.border
    }`,
    color: outlined
      ? theme?.button?.[color]?.background || theme?.button?.primary?.background
      : theme?.button?.[color]?.color || theme?.button?.primary?.color,
    cursor: 'pointer',

    '@media screen and (min-width: 600px)': {
      ...fontbox(
        appFonts?.inter,
        isTableBtn ? 10 : 14,
        isTableBtn ? 500 : 700,
        isTableBtn ? 16 : 18,
      ),
    },

    '&:hover': {
      backgroundColor: darken(theme?.button?.[color]?.background, -0.25),
      color: theme?.button?.primary?.color,
    },

    '&:disabled': {
      cursor: 'default',
      opacity: 0.65,

      '&:hover': {
        backgroundColor: theme?.button?.[color]?.background,
      },
    },

    link: {
      ...borderRadius(0),
      ...paddingX(0),
      ...paddingY(0),
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'unset',
      color: theme?.button?.[color]?.border || theme?.button?.primary?.border,
      flex: 'unset',
      marginRight: 'auto',
      width: 'auto',

      '&:hover': {
        backgroundColor: 'transparent',
        color: darken(theme?.button?.[color]?.border, -0.25),
      },
    },
  },
})
