/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { css } from "./css";
import { Button } from 'primereact/button';
import CustomButton from "../custom-button/custom-button";
import Client from '../../client';
import { changeCurrentHorse, selectedCurrentHorse } from "../../store/horseSlice";
import { useAppDispatch, useAppSelector } from "../../shared/model";

export default function ModalButtonSet(props) {
  const {
    onCancel,
    onSubmit,
    isSubmitting,
    submitButtonText,
    submitButtonColor,
    disabled
  } = props;
  
  return (
    <div css={css.modalButtons}>
      <Button
        css={css.cancel}
        type="button"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        css={css.save}
        disabled={disabled}
        type="submit"
        onClick={onSubmit}
        disabled={disabled}
        style={{ backgroundColor: submitButtonColor }}
      >
        {submitButtonText}
      </Button>
    </div>
  );
};