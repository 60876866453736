/** @jsxImportSource @emotion/react */
import { useState } from "react";
import Header from "../header/header";
import { Outlet, useLocation, } from "react-router-dom";

import { css } from "./css";

const Main = () => {
  const loc = useLocation();
  const [activePage, setActivePage] = useState(loc.pathname.slice(1));

  return (
    <div css={css} className="site">
      <Header activePage={activePage} setActivePage={setActivePage} />
      <main css={css.main}>
        <Outlet context={[activePage, setActivePage]} />
      </main>
    </div>
  );
};

export default Main;