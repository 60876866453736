import {
  flexbox,
  fontbox,
  borderRadius,
  fullWidth,
  square,
  posAbsolute,
  posRelative,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  videocard: {
    ...borderRadius(8),
    ...flexbox('column', 'center', 'center'),
    ...fullWidth(),
    ...posRelative(),
    backgroundColor: appColors?.grays?.[0],
    borderRadius: 8,
    border: `1px solid ${appColors?.grays?.[100]}`,
    overflow: 'hidden',

    '& p': {
      ...borderRadius(4),
      ...fontbox(appFonts.inter, 10, 500, 10 * 1.44),
      ...posAbsolute(null, 10, 10, 10),
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: 'rgba(255, 255, 255, 0.5)',
      letterSpacing: '0.15em',
      textAlign: 'center',
    },

    '> div': {
      ...borderRadius(7),
    },

    loading: {
      ...flexbox('column', 'center', 'center'),
      minHeight: 300,

      svg: {
        ...square(120),
      },
    },
  },
}
