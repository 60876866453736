/** @jsxImportSource @emotion/react */

import Select from "react-select";
import AsyncSelect from "react-select/async";

import {
  fontbox,
  fullWidth,
  paddingY,
  square,
  theme,
} from '../../emotion/utils';

import IconTooltip from '../icons/icon-tooltip';
import chevronDown from '../../assets/chevrondown.svg';
import selectArrow from '../../assets/chevrondown.svg';

export default function FormSelect({
  formik,
  name,
  label,
  options = [],
  none,
  all,
  multi,
  labelPosition,
  onChange,
  stack = false,
  tooltipText,
  color,
  size,
  viewBox,
  icon,
  readOnly,
  helperText,
  showIcon = true,
  style,
  value,
  async,
  loadOptions,
  getOptionLabel,
  getOptionValue,
  isFilter = false,
}) {
  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const noneEl = { value: "", label: "Select..." };
  const allEl = { value: "all", label: "All" };

  const defaultEls = [];
  if (all) {
    defaultEls.push(allEl);
  }
  if (none) {
    defaultEls.push(noneEl);
  }
  const formatOptions = options.map((opt) => ({
    value: opt.value,
    label: opt.display || opt.label,
  }));

  if (!multi) {
    formatOptions.unshift(...defaultEls);
  }

  const selectProps = {
    className: errorClass,
    id: name,
    name: name,
    isDisabled: readOnly,
    onChange: (val) => {
      if (async && getOptionValue) {
        val = { value: getOptionValue(val) };
      }

      if (onChange) {
        onChange(val, formik, name);
      }

      formik.setFieldTouched(name, true);
      formik.setFieldValue(name, val.value);
    },
    value: formatOptions.find((opt) => {
      if (formik.values[name]) {
        return opt.value === formik.values[name];
      } else {
        return async ? undefined : "Select...";
      }
    }),
    options: formatOptions,
    menuPosition: "fixed",
    // menuIsOpen: true,
  };

  if (multi) {
    selectProps.className = `multiselect formSelect ${errorClass}`;
    // selectProps.className = errorClass;
    selectProps.isMulti = true;
    selectProps.onChange = (values) => {
      onChange && onChange(values);
      formik.setFieldTouched(name, true);
      formik.setFieldValue(name, values);
    };
    selectProps.value = formatOptions.filter((opt) => {
      if (value) {
        return opt.value === value;
      }
      return opt.value === formik.values[name];
    });
  }

  const inputEl = async ? (
    <AsyncSelect
      {...selectProps}
      loadOptions={loadOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
    />
  ) : (
    <Select {...selectProps} />
  );

  return (
    <div
      className={`form-group-item ${
        labelPosition === "top"
          ? "label-top"
          : labelPosition === "left"
          ? "label-left"
          : ""
      } ${stack ? "stack" : ""}`}
      style={{ flex: 1 }}
    >
      {(labelPosition === "top" || showIcon) && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {labelPosition === "top" && <label htmlFor={name}>{label}</label>}
          {showIcon && (
            <span className="custom-icon">
              <IconTooltip
                id={`${name}-icon`}
                tooltipText={tooltipText}
                color={color}
                size={size}
                icon={icon}
                viewBox={viewBox}
              />
            </span>
          )}
        </div>
      )}

      <div css={[css.container, isFilter && css.isFilter]}>
        {inputEl}
        {helperText && <p>{helperText}</p>}
      </div>

      {labelPosition !== "top" && label && (
        <label htmlFor={name}>{label}</label>
      )}
    </div>
  );
}

const css = {
  container: {
    ...fullWidth(),

    "> div": {
      "> div[class$='-control']": {
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.lightGrey}`,
        height: 40,

        "&[aria-disabled='true']": {
          cursor: "not-allowed",
          opacity: 0.5,
        },

        // "> div[class$='-ValueContainer']": {
        "> div:first-of-type": {
          ...paddingY(0),
          paddingRight: 0,
          paddingLeft: 10,

          "> div[class$='-placeholder']": {
            ...fontbox(theme.fonts.inter, 16, 400, 16 * 1.44),
            color: theme.colors.suvaGray,
          },

          // "> div[class$='-singleValue']": {
          "> div:first-of-type": {
            ...fontbox(theme.fonts.inter, 16, 400, 16 * 1.44),
            color: theme.colors.nero,
            marginLeft: 0,
          },

          // "> div[class$='-Input']": {
          "> div:last-of-type": {
            ...paddingY(0),
            margin: 0,

            "> input": {
              ...fontbox(
                `${theme.fonts.inter}!important`,
                "16px!important",
                "400!important",
              ),
              ...fullWidth(),
              color: `${theme.colors.nero}!important`,
              cursor: "pointer",
              lineHeight: `${16 * 1.44}px!important`,
              minWidth: `200px!important`,
              outline: "none!important",
            },
          },
        },

        // "> div[class$='-IndicatorsContainer']": {
        "> div:last-of-type": {
          width: 40,

          "> span[class$='-indicatorSeparator']": {
            display: "none",
          },

          "> div[class$='-indicatorContainer']": {
            ...square(38),
            backgroundImage: `url(${chevronDown})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: 0,

            svg: {
              display: "none",
            },
          },
        },
      },

      "> div[class$='-MenuPortal']": {
        ...fontbox(theme.fonts.inter, 16, 400, 16 * 1.44),
      },
    },
  },

  isFilter: {
    "> div[class$='-container']": {
      "> div[class$='-control']": {
        height: 28,
        minHeight: 0,

        "&[aria-disabled='true']": {
          cursor: "not-allowed",
          opacity: 0.5,
        },

        // "> div[class$='-ValueContainer']": {
        "> div:first-of-type": {
          // "> div[class$='-singleValue']": {
          "> div:first-of-type": {
            ...fontbox(theme.fonts.inter, 12, 400, 12),
            color: theme.colors.black,
          },

          "> div[class$='-Input']": {
            "> input": {
              ...fontbox(
                `${theme.fonts.inter}!important`,
                "12px!important",
                "400!important",
              ),
              ...fullWidth(),
              color: `${theme.colors.nero}!important`,
              cursor: "pointer",
              lineHeight: `12px!important`,
              minWidth: `200px!important`,
              outline: "none!important",
            },
          },
        },

        // "> div[class$='-IndicatorsContainer']": {
        "> div:last-of-type": {
          width: 26,

          // "> span[class$='-indicatorSeparator']": {
          //   display: "none",
          // },

          "> div[class$='-indicatorContainer']": {
            ...square(26),
            backgroundImage: `url(${selectArrow})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: 0,

            svg: {
              display: "none",
            },
          },
        },
      },

      "> div[class$='-MenuPortal']": {
        ...fontbox(theme.fonts.inter, 12, 400, 12 * 1.44),
      },
    },
  },
};
