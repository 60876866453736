/** @jsxImportSource @emotion/react */

import Icon from "../icon/icon";

import { css } from "./css";

const MobTrigger = (props) => {
  const { navOpen = false, setNavOpen = () => {} } = props;

  function handleTrigger() {
    setNavOpen(!navOpen);
  }

  const getIcon = !navOpen ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path d="M34.6875 20C34.6875 20.2486 34.5887 20.4871 34.4129 20.6629C34.2371 20.8387 33.9986 20.9375 33.75 20.9375H6.25C6.00136 20.9375 5.7629 20.8387 5.58709 20.6629C5.41127 20.4871 5.3125 20.2486 5.3125 20C5.3125 19.7514 5.41127 19.5129 5.58709 19.3371C5.7629 19.1613 6.00136 19.0625 6.25 19.0625H33.75C33.9986 19.0625 34.2371 19.1613 34.4129 19.3371C34.5887 19.5129 34.6875 19.7514 34.6875 20ZM6.25 10.9375H33.75C33.9986 10.9375 34.2371 10.8387 34.4129 10.6629C34.5887 10.4871 34.6875 10.2486 34.6875 10C34.6875 9.75136 34.5887 9.5129 34.4129 9.33709C34.2371 9.16127 33.9986 9.0625 33.75 9.0625H6.25C6.00136 9.0625 5.7629 9.16127 5.58709 9.33709C5.41127 9.5129 5.3125 9.75136 5.3125 10C5.3125 10.2486 5.41127 10.4871 5.58709 10.6629C5.7629 10.8387 6.00136 10.9375 6.25 10.9375ZM33.75 29.0625H6.25C6.00136 29.0625 5.7629 29.1613 5.58709 29.3371C5.41127 29.5129 5.3125 29.7514 5.3125 30C5.3125 30.2486 5.41127 30.4871 5.58709 30.6629C5.7629 30.8387 6.00136 30.9375 6.25 30.9375H33.75C33.9986 30.9375 34.2371 30.8387 34.4129 30.6629C34.5887 30.4871 34.6875 30.2486 34.6875 30C34.6875 29.7514 34.5887 29.5129 34.4129 29.3371C34.2371 29.1613 33.9986 29.0625 33.75 29.0625Z" fill="#BDFCFD"/>
    </svg>
  ) : <Icon icon="X" fill='#BDFCFD' />;

  return (
    <div css={css?.mobTriggerContain}>
      <button
        id="mobTrigger"
        css={[css?.mobTriggerContain?.menu, navOpen ? "opened" : ""]}
        onClick={handleTrigger}
        aria-label="Main Menu"
      >
        {getIcon}
      </button>
    </div>
  );
};

export default MobTrigger;
