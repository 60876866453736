/** @jsxImportSource @emotion/react */
import {useSortable} from "@dnd-kit/sortable"
import { useTheme } from "@emotion/react";
import { Button as PrButton } from "primereact/button";

import { css } from "./css";

const PillButton = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition} = useSortable({id: [props.children]})
  const type = props?.type || "button";
  const isLink = props?.link || false;
  const color = props?.color || "pill";

  const theme = useTheme();

  const cssContainer = {
    ...css(theme, color).container,
    ...(isLink && css(theme, color).container?.link),
  };

  return <PrButton ref={setNodeRef} {...attributes} {...listeners} unstyled type={type} css={cssContainer} {...props} />;
};

export default PillButton;
