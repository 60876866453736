import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  number_of_stalls: "",
  total_number_of_stalls: ""
};

export const barnSetupSlice = createSlice({
  name: 'barnSetup',
  initialState,
  reducers: {
    changeNumberOfStalls: (state, action) => {
      state.number_of_stalls = action.payload;
    },
    changeTotalNumberOfStalls: (state, action) => {
      state.total_number_of_stalls = action.payload;
    }
  }
});

export const { changeNumberOfStalls, changeTotalNumberOfStalls } = barnSetupSlice.actions

export const barnSetupReducer = barnSetupSlice.reducer;