/** @jsxImportSource @emotion/react */

import { useRef } from "react";

import DragAndDrop from "../drag-ndrop/drag-ndrop";

import { cssFormElements } from '../form-element/css'
import { fullWidth } from "../../emotion/utils";

export default function FormFileUpload(props) {
  const {
    formik,
    name,
    label,
    placeholder,
    readOnly,
    displayType = "image",
    accept = ".png,.jpeg,.jpg",
  } = props;

  const inputRef = useRef();

  const errorClass = formik.errors[name] && formik.touched[name] ? "error" : "";

  async function onFileChange(file) {
    if (!file) return;

    await formik.setFieldValue(`${name}`, file, true);
    await formik.validateForm();
  }

  return (
    <div css={cssFormElements} style={{ ...fullWidth() }}>
      <label htmlFor={name}>
        {label}
      </label>
        
      <input
        name={name}
        accept={accept}
        id={name}
        type="file"
        ref={inputRef}
        className={errorClass}
        hidden
        disabled={readOnly}
        onChange={(e) => onFileChange(e.target.files[0])}
      />

      {displayType === "image" && (
        <DragAndDrop
          className={errorClass}
          onChange={onFileChange}
          readOnly={readOnly}
          onClick={() => inputRef.current.click()}
          file={formik.values[name]}
          placeholder={formik.values[`${name}_url`] || placeholder}
        />
      )}
    </div>
  );
}
