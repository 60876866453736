import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Marketing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check user status and redirect immediately
    const user = window.localStorage.getItem('user');
    if (user) {
      console.log('User found..');
      navigate("/login");
    } else {
      console.log('User not found, redirecting to external site..');
      window.location.href = "https://horsewise.ai";
    }
  }, [navigate]);

  // Return null to prevent rendering the component
  return null;
};

export default Marketing;