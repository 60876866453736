import Cookies from 'universal-cookie';

const ENV = process.env.NODE_ENV;
const SERVER_PROTOCOL = process.env.REACT_APP_SERVER_PROTOCOL;
const SERVER_HOST = process.env.REACT_APP_SERVER_HOST;
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;


function NetworkError() {
  var temp = Error.apply(this, arguments);
  temp.name = this.name = 'NetworkError';
  this.message = temp.message;
  if(Object.defineProperty) {
      // getter for more optimizy goodness
      /*this.stack = */Object.defineProperty(this, 'stack', { 
          get: function() {
              return temp.stack
          },
          configurable: true // so you can change it if you want
      })
  } else {
      this.stack = temp.stack
  }
}

NetworkError.prototype = Object.create(Error.prototype, {
  constructor: {
      value: NetworkError,
      writable: true,
      configurable: true
  }
});

const isIP = (str) => {
  const regex = new RegExp(
    "^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$",
  );
  return regex.test(str);
};

const serverUrl = `${SERVER_PROTOCOL}${SERVER_HOST}${
  isIP(SERVER_HOST) ? `:${SERVER_PORT}` : ""
}`;

export const ENDPOINT =
  ENV === "production" ? serverUrl : "http://localhost:3001";

const parseResponse = async (response) => {
  let jsonRsp = null;
  try {
    jsonRsp = await response.json();
  } catch (e) {
    console.error("could not parse res body");
  }

  if (!response.ok) {
    const bodyError = jsonRsp?.error?.message || jsonRsp?.error || "Error";
    const err = new NetworkError(bodyError);
    err.field = jsonRsp?.field;
    err.status = response.status;
    err.message = bodyError
    throw err;
  }
  return jsonRsp;
};

const createBody = (data) => {
  const isMultipart = !!Object.values(data).find((v) => v instanceof File);

  if (!isMultipart) {
    return JSON.stringify(data);
  }

  const formbody = new FormData();
  const json = {};
  Object.entries(data).forEach(([key, val]) => {
    if (val instanceof File) {
      formbody.append(key, val);
    } else {
      json[key] = val;
    }
  });

  formbody.append("json", JSON.stringify(json));

  return formbody;
};

const createClient = (endpoint) => {
  const callApi = async (method, path, data, token) => {
    const options = {
      method,
      headers: {
        Accept: "application/json"
      },
    };

    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
    }

    if (["POST", "PATCH", "PUT"].includes(method)) {
      const body = createBody(data);
      options.body = body;
      if (!(body instanceof FormData)) {
        options.headers["Content-Type"] = "application/json";
      }
    }

    try {
      const url = `${endpoint}${path}`;
      const response = await fetch(url, options);
      const body = await parseResponse(response);
      return body
    } catch (error) {
      throw error;
    }
  };

  return {
    get: (path) => callApi("GET", path),
    post: (path, data, token) => callApi("POST", path, data, token),
    patch: (path, data) => callApi("PATCH", path, data),
    put: (path, data) => callApi("PUT", path, data),
    delete: (path) => callApi("DELETE", path),
  };
};

const Client = createClient("/api");
console.log("Endpoint");
console.log(ENDPOINT);

export default Client;