import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStall: null
};

export const stallSlice = createSlice({
  name: 'stall',
  initialState,
  reducers: {
    changeCurrentStall: (state, action) => {
      state.currentStall = action.payload
    },
  },
});

export const selectedCurrentStall = (state) => state.stall.currentStall;

export const { changeCurrentStall } = stallSlice.actions

export const stallReducer = stallSlice.reducer;