/** @jsxImportSource @emotion/react */

import { css } from './css';

const FormTextarea = ({
  formik,
  name,
  onInput,
  readOnly=false,
  style,
  // testChange,
  value,
  label,
  placeholder,
  rows,
  onChange,
}) => {
  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const textareaProps = {
    css: css.textarea,
    className: errorClass,
    style: style,
    id: name,
    name,
    onBlur: formik?.handleBlur,
    onChange: onChange || formik?.handleChange,
    onInput: onInput,
    value: value,
    placeholder,
    rows: rows,
  };

  if(readOnly) textareaProps["disabled"] = readOnly

  return (
    <div css={css.container}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea {...textareaProps} />
    </div>
  );
};

export default FormTextarea;