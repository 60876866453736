import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'
import {
  flexbox,
  fontbox,
  fullWidth,
  posRelative,
  square,
} from '../../emotion/utils'

export const css = {
  cameraArea: {
    ...flexbox('row', 'flex-start', 'flex-start', 8, 'wrap'),
    ...fullWidth(),
    ...posRelative(),
  },

  formEl: {
    ...flexbox('column', 'flex-start', 'flex-start', 2),
    ...fullWidth(),

    label: {
      ...fontbox(appFonts?.inter, 12, 700, 14),
      color: appColors?.grays?.[900],

      '@media screen and (min-width: 600px)': {
        fontSize: 14,
      },
    },

    '> div': {
      ...fullWidth(),
    },
  },

  settings: {
    ...flexbox('column', 'center', 'center'),
    ...square(36),
    cursor: 'pointer',
  },
}
