/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import { css } from './css'
import { appColors } from '../../emotion/appColors'
import React, { useState, useEffect, useContext } from 'react'
import ModalButtonSet from '../modal-set-button/modal-set-button'
import FormTextInput from '../../components/form-text-input/form-text-input'
import FormTextarea from '../../components/form-textarea/form-textarea'
import FormSelect from '../../components/form-select/form-select-simplified'
import { useMutation } from '../../hooks'
import Client from '../../client'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useQuery } from '../../hooks'
import FormFileUpload from '../../components/form-file-upload';
import { flexbox } from '../../emotion/utils'
import AuthContext from '../../context/auth-context';

export default function EditHorseModal({
  setShowModal = () => {},
  open = true,
  onSuccess = () => {},
  horse,
}) {
  const initialValues = {
    name: horse?.name || '',
    assign_stall: horse?.stall_id || '',
    description: horse?.description || '',
    ferrier_name: horse?.ferrier_name ||'',
    ferrier_phone: horse?.ferrier_phone || '',
    gender: horse?.gender || '',
    avatar: horse?.avatar_url,
    vet: horse?.vets && horse.vets.length > 0 ? horse.vets[0].id : null,
  }


  const handleClose = () => {
    setShowModal(false)
  }

  const [hasError, setHasError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(open)

  const handleCancel = () => {
    setIsModalOpen(false)
    setShowModal(false)
  }

  const { userData } = useContext(AuthContext);

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: 'add-horse',
    networkMode: 'always',
    mutationFn: async data => {
      if(horse) {
        const response = Client.put(`/horses/${horse.id}`, data);
        return response;
      } else {
        const response = Client.post(`/horses`, data);
        return response;
      }
    },
    onSuccess: values => {
      setShowModal(false)
      onSuccess(values.body)
    }
  })

  const { data: stallData } = useQuery("/stalls");
  const { data: vetData } = useQuery("/vets");
  const readOnly = !(userData.is_admin || userData.is_barn_manager || 
    (userData?.horses_owned && userData.horses_owned.find((h) => h.id === horse?.id)) || false)

  const stallOptions = stallData?.data?.map(stall => ({
    value: stall.id,
    label: stall.name,
  }));

  const vetOptions = vetData?.data?.map(vet => ({
    value: vet.id,
    label: vet.name,
  }));


  const stallGenders = [
    {
      value: 'MARE',
      label: 'Mare'
    },
    {
      value: 'GELDING',
      label: 'Gelding'
    },
    {
      value: 'STALLION',
      label: 'Stallion'
    }
  ];

  const header = (
    <span css={css.dialogHeader} className="dialog-header">
      {horse ? 'Edit a Horse' : 'Add a Horse'}
    </span>
  );

  const footer = formik => (
    <ModalButtonSet
      onCancel={handleCancel}
      onSubmit={formik.handleSubmit}
      isSubmitting={formik.isSubmitting}
      submitButtonText="Save changes"
      submitButtonColor={appColors?.greens?.base}
    />
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await submitRequest(values)
        } catch (error) {
          console.error(error)
        } finally {
          setSubmitting(false)
        }
      }}
      validationSchema={Yup.object({
        name: Yup.string().label('Horse Name').required(),
      })}
      enabledReinitialize
    >
      {formik => (
        <Dialog
          header={header}
          footer={footer(formik)}
          visible={isModalOpen}
          style={{ width: 400 }}
          onHide={handleCancel}
        >
          <Form style={{
            ...flexbox('column', 'flex-start', 'flex-start', 16),
            paddingTop: 12,
          }}>
            <FormTextInput
              id="name"
              name="name"
              label="Name"
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="Enter your horse name"
              required
              readOnly={readOnly}
            />

            <FormSelect
              formik={formik}
              name="gender"
              label="Gender"
              labelPosition="top"
              required
              placeholder="Select gender"
              options={stallGenders}
              value={formik.values.gender}
              readOnly={readOnly}
            />

            <FormSelect
              formik={formik}
              name="assign_stall"
              label="Assign Stall"
              labelPosition="top"
              placeholder="Select stall"
              required
              options={stallOptions}
              value={formik.values.assign_stall}
              readOnly={!(userData.is_admin || userData.is_barn_manager)} //Only two roles allowed to change a stall
            />

            <FormSelect
              formik={formik}
              name="vet"
              label="Vet"
              labelPosition="top"
              placeholder="Select a Vet"
              options={vetOptions}
              value={formik.values.vet}
              readOnly={readOnly}
              required
            />
            
            <FormTextInput
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.ferrier_name}
              id="ferrier_name"
              name="ferrier_name"
              label="Ferrier Name"
              placeholder="Enter Ferrier Name"
              readOnly={readOnly}
            />
            
            <FormTextInput
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.ferrier_phone}
              name="ferrier_phone"
              label="Ferrier Phone"
              placeholder="Enter Ferrier Phone"
              readOnly={readOnly}
            />
            
            <FormTextInput
              formik={formik}
              onBlur={formik.handleBlur}
              onChange={(e) => {formik.handleChange(e)}}
              textarea
              id="description"
              name="description"
              label="Description"
              placeholder="Enter horse notes here..."
              readOnly={readOnly}
            />

            <FormFileUpload
              name="avatar"
              label="Horse Image"
              formik={formik}
              placeholder="Click to add horse image"
              helperText="Accepts png and jpeg"
              readOnly={readOnly}
            />
          </Form>
        </Dialog>
      )}
    </Formik>
  )
}
