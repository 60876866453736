import {
  borderRadius,
  darken,
  flexbox,
  fontbox,
  fullWidth,
  marginAuto,
  posAbsolute,
  posRelative,
  themes,
  transition,
} from '../../emotion/utils'

import { appFonts } from '../../emotion/appFonts'

export const css = {
  container: theme => ({
    backgroundColor: theme?.background?.primary,
    minHeight: '100vh',
    paddingTop: 32,

    '@media screen and (min-width: 576px)': {
      paddingTop: 64,
    },
  }),

  block: {
    ...marginAuto(),
    maxWidth: 360,
    width: '80%',

    '> form': {
      ...flexbox('column', 'center', 'flex-start', 32),
      ...posRelative(),

      '> *': {
        ...fullWidth(),
        textAlign: 'center',
      },
    },
  },

  formBlock: () => ({
    ...flexbox('column', 'center', 'flex-start', 24),
    display: 'flex',

    '> *': {
      ...fullWidth(),
    },

    intro: theme => {
      // console.log('login cs: intro - theme', theme);

      return {
        ...flexbox('column', 'center', 'center', 12),

        h1: {
          ...fontbox(appFonts.inter, 20, 600, 28),
          // color: theme?.text?.primary,
          // color: themes[`${theme}`].colors.text.primary,
          color: theme?.text?.primary,

          '@media screen and (min-width: 576px)': {
            ...fontbox(appFonts.inter, 30, 600, 38),
          },
        },

        p: {
          ...fontbox(appFonts.inter, 12, 400, 20),
          color: theme?.text?.primary,

          '@media screen and (min-width: 576px)': {
            ...fontbox(appFonts.inter, 16, 400, 24),
          },
        },
      }
    },
    forgotlink: theme => {
      // console.log('login cs: intro - theme', theme);

      return {
        ...flexbox('column', 'start', 'center', 12),

        '> span': {
          ...fontbox(appFonts.inter, 12, 400, 20),
          color: theme?.text?.primary,

          '@media screen and (min-width: 576px)': {
            ...fontbox(appFonts.inter, 14, 400, 24),
          },
          '&:hover': {
            color: darken(theme.button.primary.background, -0.25),
            cursor: 'pointer',
          },
        },
      }
    },

    elements: {
      ...flexbox('column', 'center', 'flex-start', 20),

      '> *': {
        ...fullWidth(),
      },
    },
  }),

  errorBox: theme => ({
    ...borderRadius(12),
    ...flexbox('column', 'center', 'space-between', 55),
    ...posAbsolute(null, -12, -12, -12, 1),
    backgroundColor: theme?.background?.primary,
    border: `2px solid ${theme?.button?.danger?.background}`,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.25)',
    minHeight: 300,
    padding: 10,
    width: 'calc(100% + 20px)!important',

    msg: {
      ...flexbox('column', 'center', 'center', 8),

      h2: {
        ...fontbox(appFonts.inter, 30, 600, 38),
        // color: theme?.text?.danger,
      },

      p: {
        ...fontbox(appFonts.inter, 16, 400, 24),
        color: theme?.text?.primary,

        '@media (min-width: 768px)': {
          ...fontbox(appFonts.inter, 20, 400, 28),
        },
      },
    },

    btn: {
      ...fullWidth(),
    },
  }),

  form: {
    borderRadius: '2rem',
    boxShadow: '10px 10px 20px 0 rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '5px',
    padding: '15px',
    position: 'relative',
    backgroundColor: '#ffffff',
    maxHeight: '600px',
    maxWidth: '500px',
    width: '100%',

    '@media (min-width: 992px)': {
      padding: '75px',
    },

    '> *': {
      width: '100%',
    },
  },

  account: theme => ({
    ...fontbox(themes.fonts.inter, 12, 400, 20),
    color: theme.text.tertiary,

    '@media screen and (min-width: 576px)': {
      ...fontbox(themes.fonts.inter, 14, 400, 20),
    },

    '> span': {
      ...fontbox(themes.fonts.inter, 12, 600, 20),
      ...transition(),
      color: theme.button.primary.background,
      cursor: 'pointer',

      '@media screen and (min-width: 576px)': {
        ...fontbox(appFonts.inter, 14, 600, 20),
      },

      // "&:hover": {
      //   color: darken(theme.button.primary.background, -0.25),
      // },
    },
  }),
  signup: theme => {
    // console.log('login cs: intro - theme', theme);

    return {
      //...flexbox("column", "start", "center", 12),
      ...fontbox(appFonts.inter, 14, 400, 20),

      '> span': {
        ...fontbox(appFonts.inter, 14, 700, 20),
        color: theme?.text?.primary,

        '@media screen and (min-width: 576px)': {
          ...fontbox(appFonts.inter, 14, 700, 24),
        },
        '&:hover': {
          color: darken(theme.button.primary.background, -0.25),
          cursor: 'pointer',
        },
      },
    }
  },
}
