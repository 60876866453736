/** @jsxImportSource @emotion/react */
import { Checkbox } from 'primereact/checkbox'

import { css } from './css'

const FormCheckBox = ({ label, groupLabel, ...props }) => {
  return (
    <>
      {groupLabel && <div css={css.groupLabel}>{groupLabel}</div>}
      <div css={css.container}>
        <Checkbox
          inputId={props.id || props.name}
          {...props}
          checked={props.formik?.values[props.name] === true}
          onChange={(e) => props.formik?.handleChange(e)}
        />
        <label htmlFor={props.id || props.name}>{label}</label>
      </div>
    </>
  )
}

export default FormCheckBox
