import {
  borderRadius,
  dimensions,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  marginAuto,
  paddingX,
  paddingXY,
  paddingY,
  posAbsolute,
  posRelative,
  posStatic,
  square,
  transition,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  navigation: {
    ...posAbsolute(60, 0, null, 0, 100),
    boxShadow: '0px 1px 4px 0px rgba(16, 24, 40, 0.25)',
    display: 'none',

    '@media screen and (min-width: 768px)': {
      ...fullWidth(),
      ...posStatic(),
      boxShadow: 'none',
      display: 'block!important',
    },

    '&.open': {
      display: 'block',
    },

    '> ul': {
      ...flexbox('column', 'flex-start', 'flex-start', 0),
      ...fullWidth(),
      ...paddingY(8),
      backgroundColor: appColors?.greens?.base,

      '@media screen and (min-width: 768px)': {
        ...flexbox('row', 'center', 'flex-end', 0),
        ...paddingY(0),
      },

      li: {
        ...flexbox('row', 'center', 'flex-start', 7),
        ...fontbox(appFonts?.inter, 14, 400, 14),
        ...fullWidth(),
        ...paddingY(10),
        ...paddingX(17),
        color: appColors?.greens?.textlight,
        cursor: 'pointer',

        '@media screen and (min-width: 768px)': {
          ...paddingY(0),
          ...paddingX(15),
          fontWeight: 500,
          lineHeight: '60px',
          width: 'auto',
        },
        '@media screen and (min-width: 850px)': {
          ...paddingX(20),
          ...fontbox(appFonts?.inter, 16, 500, 60),
        },
        '@media screen and (min-width: 992px)': {
          ...paddingX(24),
          fontSize: 18,
        },

        // Icon
        '> div': {
          '@media screen and (min-width: 768px)': {
            display: 'none',
          },

          '> svg': {
            ...square(18),
          },
        },

        // Last li
        '&:last-of-type': {
          '@media screen and (min-width: 768px)': {
            paddingRight: 0,
          },

          // Icon
          '> div': {
            '@media screen and (min-width: 768px)': {
              display: 'block',
            },

            '>svg': {
              '@media screen and (min-width: 768px)': {
                ...dimensions(32, 32),
              },
            },
          },

          // Text
          '> span': {
            '@media screen and (min-width: 768px)': {
              display: 'none',
            },
          },
        },

        '&.active': {
          ...posRelative(),
          backgroundColor: appColors?.greens?.navbuttonactivebg,
          color: appColors?.grays?.[0],

          // border
          '&::before': {
            ...posAbsolute(0, null, 0, 0),
            ...fullHeight(),
            backgroundColor: appColors?.reds?.secondary,
            content: '""',
            width: 6,

            '@media screen and (min-width: 768px)': {
              ...fullWidth(),
              ...posAbsolute('auto', 0, 0, 0),
              height: 6,
            },
          },
        },
      },
    },

    dropdown: {
      // ...borderRadius(4),
      ...marginAuto(),
      ...paddingXY(6),
      ...posRelative(null, null, null, null, 1),
      ...transition(),
      backgroundColor: 'transparent',
      border: `1px solid ${appColors?.greens?.textlight}35`,
      boxShadow: 'none',
      // marginBottom: 12,
      width: 'calc(100% - 12px)',

      '@media screen and (min-width: 768px)': {
        ...paddingX(16),
        ...paddingY(0),
        border: 0,
        margin: 0,
        width: 'auto',
      },
      '@media screen and (min-width: 850px)': {
        ...paddingX(20),
      },
      '@media screen and (min-width: 992px)': {
        ...paddingX(24),
      },

      '&::after': {
        ...borderRadius(8),
        ...posAbsolute(10, 0, 10, 0, -1),
        ...transition(),
        backgroundColor: appColors?.grays?.[0],
        content: '""',
        opacity: 0,
      },

      '&:hover': {
        '&::after': {
          opacity: 1,
        },
        
        '.p-dropdown-label': {
          color: appColors?.greens?.base,
        },

        '.p-dropdown-trigger': {
          color: appColors?.greens?.base,
        },
      },

      '.p-dropdown-label': {
        ...fontbox(appFonts?.inter, 14, 500, 14),
        ...transition(),
        color: appColors?.greens?.textlight,

        '@media screen and (min-width: 768px)': {
          lineHeight: '60px',
          padding: 0,
        },
        '@media screen and (min-width: 850px)': {
          ...fontbox(appFonts?.inter, 16, 500, 60),
        },
        '@media screen and (min-width: 992px)': {
          fontSize: 18,
        },
      },

      '.p-dropdown-trigger': {
        ...transition(),
        color: appColors?.greens?.textlight,

        '@media screen and (min-width: 768px)': {
          marginLeft: 8,
          width: 14,
        },
      },
    },

    barnName: {
      backgroundColor: `${appColors?.grays?.[0]}15`,
      cursor: 'auto',
    },
  },

  mobTriggerContain: {
    ...flexbox('row', 'flex-start', 'flex-start', 0),
    ...square(40),

    '@media screen and (min-width: 768px)': {
      display: 'none',
    },

    menu: {
      ...flexbox('row', 'center', 'center', 0),
      ...square(40),
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
    },
  },

  confirmDialog: {
    ...borderRadius(8),
    backgroundColor: appColors?.grays?.[0],
    overflow: 'hidden',

    content: {
      ...paddingX(16),
      ...paddingY(32),
    },

    footer: {
      ...flexbox('row', 'center', 'space-between', 12),
      ...paddingX(16),
      ...paddingY(8),
      backgroundColor: appColors?.grays?.[50],

      '> *': {
        flex: 1,
        outline: 'none',
      },
    },
  },
}
