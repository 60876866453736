import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentHorse: null
};

export const horseSlice = createSlice({
  name: 'horse',
  initialState,
  reducers: {
    changeCurrentHorse: (state, action) => {
      state.currentHorse = action.payload
    },
  },
});

export const selectedCurrentHorse = (state) => state.horse.currentHorse;

export const { changeCurrentHorse } = horseSlice.actions

export const horseReducer = horseSlice.reducer;