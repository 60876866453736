import { object, string, number, date, InferType } from 'yup';
import * as Yup from 'yup';

const editUserSchema = object({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  //phone: Yup.string().required('Phone is required'),
  //assign_horse: Yup.array().min(1, 'At least one horse must be assigned'),
});

export default editUserSchema;