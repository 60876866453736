import {
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  darken,
  fontbox,
  paddingX,
  paddingY,
  transition,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = (theme, color) => ({
  container: {
    // ...borderRadius(8),
    ...fontbox(appFonts?.inter, 12, 600, 12 * 1.5),
    ...paddingX(16),
    ...paddingY(10),
    ...transition(),
    backgroundColor:
      theme?.button?.[color]?.color || theme?.button?.primary?.color,
    border: `1px solid ${appColors?.grays?.border2}`,
    color:
      theme?.button?.[color]?.background || theme?.button?.primary?.background,
    cursor: 'pointer',
    marginLeft: -1,
    width: 'auto',

    '@media screen and (min-width: 600px)': {
      ...fontbox(appFonts?.inter, 14, 600, 14),
    },

    '&:first-of-type': {
      ...borderTopLeftRadius(8),

      '@media screen and (min-width: 600px)': {
        ...borderBottomLeftRadius(8),
      },
    },
    '&:last-of-type': {
      ...borderTopRightRadius(8),

      '@media screen and (min-width: 600px)': {
        ...borderBottomRightRadius(8),
      },
    },

    '&.active': {
      backgroundColor: appColors?.grays?.tabActive,
    },

    '&:hover': {
      backgroundColor: darken(theme?.button?.[color]?.background, -0.25),
      color: theme?.button?.[color]?.color || theme?.button?.primary?.color,
    },

    link: {
      ...borderRadius(0),
      ...paddingX(0),
      ...paddingY(0),
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'unset',
      color: theme.button[color].border || theme.button.primary.border,
      flex: 'unset',
      marginRight: 'auto',
      width: 'auto',

      '&:hover': {
        backgroundColor: 'transparent',
        color: darken(theme.button[color].border, -0.25),
      },
    },
  },
})
