import { ReactComponent as CheckIcon } from "./assets/check-icon.svg";
import { ReactComponent as ChevronLeft } from "./assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "./assets/chevron-right.svg";
import { ReactComponent as Edit } from "./assets/edit.svg";
import { ReactComponent as Eye } from "./assets/eye.svg";
import { ReactComponent as EyeClosed } from "./assets/eye-closed.svg";
import { ReactComponent as FullScreen } from "./assets/fullscreen.svg";
import { ReactComponent as Horse } from "./assets/horse.svg";
import { ReactComponent as Horse2 } from "./assets/horse2.svg";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { ReactComponent as NavLogo } from "./assets/nav-logo.svg";
import { ReactComponent as Restore } from "./assets/restore.svg";
import { ReactComponent as Settings } from "./assets/settings.svg";
import { ReactComponent as Signout } from "./assets/signout.svg";
import { ReactComponent as Stall } from "./assets/stall.svg";
import { ReactComponent as Trash } from "./assets/trash.svg";
import { ReactComponent as TrayTab } from "./assets/tray-tab.svg";
import { ReactComponent as Upload } from "./assets/upload.svg";
import { ReactComponent as User } from "./assets/user.svg";
import { ReactComponent as User2 } from "./assets/user2.svg";
import { ReactComponent as Video } from "./assets/video.svg";
import { ReactComponent as X } from "./assets/x.svg";

export const icons = {
  CheckIcon,
  ChevronLeft,
  ChevronRight,
  Eye,
  EyeClosed,
  Edit,
  FullScreen,
  Horse,
  Horse2,
  Logo,
  NavLogo,
  Restore,
  Settings,
  Signout,
  Stall,
  Trash,
  TrayTab,
  Upload,
  User,
  User2,
  Video,
  X,
};
