import { useContext, useState } from 'react';
import { useQuery as reactQueryUseQuery } from '@tanstack/react-query';
import { useToastContext } from '../context/toast-context';

import AuthContext from '../context/auth-context';

import Client from '../client';

export function useQuery(endpoint, props = {}) {
  const { runProtected } = useContext(AuthContext);
  const { showToast } = useToastContext();
  const { isProtected = true } = props;

  const [fetched, setFetched] = useState(false);

  const { onRefetch, onFetch } = props;

  const queryFn = () => Client.get(endpoint);

  const query = reactQueryUseQuery({
    ...props,
    queryKey: [endpoint],
    queryFn: async (...args) => {
      let data = null;
      try {
        if (isProtected) {
          data = await runProtected(queryFn, ...args);
        } else {
          data = await queryFn(...args);
        }

        if (onRefetch) onRefetch(data);
        if (!fetched && onFetch) {
          onFetch(data);
          setFetched(true);
        }
      } catch (err) {
        if(props.onError)props.onError(err)
        else {
          showToast({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: 30000,
          });
        }
      }

      return data;
    },
  });

  return query;
}
