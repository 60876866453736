/** @jsxImportSource @emotion/react */

import { Dialog as PRDialog } from "primereact/dialog";

export default function Dialog(props) {
  const {
    children,
    footer,
    header = "",
    onClick = () => {},
    size = "md",
    visible = true
  } = props;

  const sizes = {
    sm: 400,
    md: 600,
    lg: 800,
    xl: 1000,
  }
  const width = sizes[size]

  const dialogProps = {
    footer,
    header,
    visible,
    onHide: () => onClick(false),
    style: { width }
  }

  return (
    <PRDialog { ...dialogProps }>
      {children}
    </PRDialog>
  )
}
