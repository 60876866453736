/** @jsxImportSource @emotion/react */

import { Password as PRPassword } from 'primereact/password'

import { cssFormElements } from '../css'

const Password = data => {
  const getLabel = data?.label && <label>{data.label}</label>

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== 'data') {
        acc[key] = data[key]
      }
      return acc
    }, {}),
  }

  const getElement = <PRPassword {...elProps} feedback={false} toggleMask />
  
  return (
    <div css={cssFormElements}>
      {getLabel}
      {getElement}
    </div>
  )
}

export default Password
