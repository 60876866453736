import React from "react";

import "./global.scss";

import css from "./footer.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={css.footerSection}>
      <div className={css.midFooter}>
        <div className={css.rectangleParent}>
          <div className={css.rectangleDiv}></div>
          <div className={css.frameChild1}></div>
        </div>
        <div className={css.vectorParent} data-animate-on-scroll>
          <b className={css.showmanship}>Horsewise</b>
        </div>
      </div>
      <div className={css.bottomFooter}>
        <div className={css.frameContainer}>
          <div className={css.showmanshipAllRightsReservWrapper}>
            <a className={css.showmanshipAllRights}>
              © 2024 Horsewise. All Rights Reserved
            </a>
          </div>
          <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>
            <div className={css.logInWrapper}>
              <a className={css.logIn}>Privacy Policy</a>
            </div>
          </Link>
          <Link to="login">
            <div className={css.logInWrapper}>
              <a className={css.logIn}>Log In</a>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Footer;
