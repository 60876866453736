import React from "react";
import { Route } from "react-router-dom";
import Login from "../pages/login/login";
import Signup from "../pages/signup/signup";


import UnProtectedLayout from "../layouts/unprotected";

const LoginRouter = (
  <Route element={<UnProtectedLayout />}>
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<Signup />} />
  </Route>
);

export default LoginRouter;