import { flexbox, fullWidth } from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'

export const css = {
  navActionBar: {
    ...flexbox('column', 'center', 'center', 24),
    ...fullWidth(),

    '@media screen and (min-width: 600px)': {
      ...flexbox('row', 'center', 'space-between', 12),
    },

    '> span': {
      marginRight: 'auto',
      order: 1,
      paddingLeft: 1,

      '@media screen and (min-width: 600px)': {
        ...flexbox('row', 'center', 'flex-start', 0),
        order: 'unset',
        paddingLeft: 0,
      },

      button: {
        minWidth: 0,
        width: 'auto',
      },
    },

    button: {
      textWrap: 'nowrap',

      '@media screen and (min-width: 600px)': {
        minWidth: 115,
      },
      '@media screen and (min-width: 768px)': {
        minWidth: 125,
      },
      '@media screen and (min-width: 992px)': {
        minWidth: 144,
      },
    },
  },

  addhorsebtn: {
    color: appColors.greens.base,
    background: appColors.grays[0],
    ':hover': {
      background: appColors.greens.base,
      color: appColors.grays[0],
    },
  },

  actionGroup: {
    ...flexbox('row', 'center', 'flex-end', 8),
    ...fullWidth(),
    order: 0,

    '@media screen and (min-width: 600px)': {
      order: 'unset',
    },

    '> button': {
      flex: 1,

      '@media screen and (min-width: 600px)': {
        flex: 'unset',
      },
    },
  },
}
