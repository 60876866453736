/** @jsxImportSource @emotion/react */
import { useState } from "react";
import Button from "../button/button";
import TabButton from "../tab-button/tab-button";
import { ButtonGroup } from 'primereact/buttongroup';

import { useNavigate } from 'react-router-dom';
import { css } from "./css";

export default function NavigationActionBar(props) {

  const {
    tabs = [],
    defaultTab = '',
    actions = [],
    onActionClick = () => {},
  } = props;

  const [activeTab, setActiveTab] = useState(defaultTab);
  const navigate = useNavigate();

  const tabEls = tabs.map((tab) => (
    <TabButton
      key={tab.label}
      className={`lt ${activeTab === tab.label ? 'active' : ''}`}
      css={css.tabButton}
      onClick={() => {
        setActiveTab(tab.label);
        navigate(tab.url);
      }}
    >{tab.label}</TabButton>
  ))

  const filterBtnGroup = (
    <ButtonGroup>
      {tabEls}
    </ButtonGroup>
  );



  const actionEls = actions.map((action) => (
    <Button
      key={action}
      css={css.addhorsebtn}
      onClick={() => onActionClick(action)}
    >
      {action}
    </Button>
  ));

  return (
    <>
      <div css={css.navActionBar}>
        {filterBtnGroup}
        <div css={css.actionGroup}>
          {actionEls}
        </div>
      </div>
    </>
  );
}