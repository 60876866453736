import {
  flexbox,
  fontbox,
  fullWidth,
  marginAuto,
  posRelative,
} from '../../emotion/utils'

import { appFonts } from '../../emotion/appFonts'
import { appColors } from '../../emotion/appColors'

export const css = theme => ({
  backgroundColor: theme?.background?.primary,
  minHeight: '100vh',
  paddingTop: 32,

  '@media screen and (min-width: 576px)': {
    paddingTop: 64,
  },

  block: {
    ...marginAuto(),
    maxWidth: 360,
    width: '80%',

    '> form': {
      ...flexbox('column', 'center', 'flex-start', 32),
      ...posRelative(),

      '> *': {
        ...fullWidth(),
        textAlign: 'center',
      },
    },
  },

  header: {
    ...flexbox('column', 'center', 'center', 24),
  },

  formBlock: {
    ...flexbox('column', 'center', 'flex-start', 24),

    '> *': {
      ...fullWidth(),
    },

    intro: {
      ...flexbox('column', 'center', 'center', 12),

      h1: {
        ...fontbox(appFonts.inter, 20, 600, 28),
        color: theme?.text?.primary,

        '@media screen and (min-width: 576px)': {
          ...fontbox(appFonts.inter, 30, 600, 38),
        },
      },
    },

    checklist: {
      ...flexbox('column', 'flex-start', 'flex-start', 12),

      check: {
        ...flexbox('row', 'center', 'flex-start', 4),
      },

      msg: isMatch => ({
        ...fontbox(appFonts?.inter, 14, isMatch ? 600 : 400, 20),
        color: isMatch
          ? appColors?.greens?.secondary
          : appColors?.blues?.tertiary?.[600],
      }),
    },
  },
})
