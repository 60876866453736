import { appColors } from '../../emotion/appColors'
import {
  borderRadius,
  flexbox,
  paddingXY,
  transition,
} from '../../emotion/utils'

export const css = theme => ({
  ...borderRadius(20),
  ...flexbox('column', 'center', 'center'),
  ...paddingXY(6),
  ...transition('all', 0.3),
  backgroundColor: 'transparent',
  border: `1px solid transparent`,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: appColors?.grays?.[0],
  },
})
