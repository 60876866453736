import { appFonts } from '../../emotion/appFonts'
import {
  flexbox,
  fontbox,
  fullWidth,
  paddingX,
  posRelative,
} from '../../emotion/utils'

export const css = theme => ({
  ...flexbox('column', 'center', 'center', 16),
  ...fullWidth(),
  ...paddingX(16),
  ...posRelative(),
  color: theme?.text?.tertiary,
  height: 'calc(100vh - 60px)',

  svg: {
    display: 'block',
    height: 'auto',
  },

  h2: {
    ...fontbox(appFonts?.inter, 14, 500, 20),

    '@media screen and (min-width: 600px)': {
      fontSize: 16,
    },

    '@media screen and (min-width: 768px)': {
      fontSize: 20,
      lineHeight: '24px',
    },
  },
})
