/** @jsxImportSource @emotion/react */

import { createElement } from "react";

import { icons } from "./icons.js";

import { css } from "./css";
import { appColors } from "../../emotion/utils.js";

const Icon = ({
  icon,
  isOpen,
  fill = null,
  stroke = null,// appColors?.grays?.[600],
}) => (
  <div css={css(fill, stroke, isOpen)}>{createElement(icons[icon])}</div>
);

export default Icon;
