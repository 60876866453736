/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { Button as PrButton } from "primereact/button";

import { css } from "./css";

const TabButton = (props) => {
  const type = props?.type || "button";
  const isLink = props?.link || false;
  const color = props?.color || "primary";

  const theme = useTheme();

  const cssContainer = {
    ...css(theme, color).container,
    ...(isLink && css(theme, color).container?.link),
  };

  return <PrButton unstyled type={type} css={cssContainer} {...props} />;
};

export default TabButton;
