/** @jsxImportSource @emotion/react */

import { css as tempCss } from './css'

import IconTooltip from "../icons/icon-tooltip";
import FormInput from "../form-input/form-input";
import FormTextarea from "../form-textarea/form-textarea";
import { posRelative } from '../../emotion/utils';

export default function FormTextInput(props) {
  const {
    formik,
    testChange,
    readOnly,
    name,
    label,
    textarea,
    number,
    // labelPosition,
    tooltipText,
    color,
    size,
    viewBox,
    icon,
    hidden,
    value,
    required = false,
    placeholder,
    onBlur,
    // onFocus,
    type="text",
  } = props;

  const css = tempCss(formik?.errors[name] && formik?.touched[name]);

  const width = "100%";

  const inputEl = textarea ? (
    <div style={{ ...posRelative() }}>
      <FormTextarea
        formik={formik}
        name={name}
        onInput={testChange}
        value={formik?.values[name] || value || ""}
        placeholder={placeholder}
        onBlur={onBlur}
        readOnly={readOnly}
      />
      {required && formik?.errors[name] && formik?.touched[name] && (
        <div css={css?.feedback}>{formik?.errors[name]}</div>
      )}
    </div>
  ) : (
    <div style={{ ...posRelative() }}>
      <FormInput
        formik={formik}
        name={name}
        number={number}
        type={type}
        onInput={(evt) => {
          if (testChange) {
            testChange(evt, name);
          }
        }}
        readOnly={readOnly}
        style={{ width }}
        value={formik?.values[name] || value || (number ? 0 : "")}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      {required && formik?.errors[name] && formik?.touched[name] && (
        <div css={css?.feedback}>{formik?.errors[name]}</div>
      )}
    </div>
  );

  return (
    <div css={css} hidden={hidden}>
      {(label || icon) && (
        <div css={css?.labelBlock}>
          <label>{label}{required && <span>*</span>}</label>

          {icon && (
            <span>
              <IconTooltip
                id={`${name}-icon`}
                tooltipText={tooltipText}
                color={color}
                size={size}
                icon={icon}
                viewBox={viewBox}
              />
            </span>
          )}
        </div>
      )}
      {inputEl}
    </div>
  );
}
