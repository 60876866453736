/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from "react";
import { changeCurrentCamera, selectedCurrentCamera } from "../../store/cameraSlice";
import { useAppDispatch, useAppSelector } from "../../shared/model";
import VideoPlayer from "../video-player-old/videoplayer";
import { css } from "./css";
import Icon from "../icon/icon";


export default function VideoComponent(props) {
  const {
    currentCamera,
    full_screen = false
  } = props;

  const initStatus = "Loading camera stream";

  const [status, setStatus] = useState(initStatus);

  const hlsUrl = currentCamera?.hls_ip
    ? `https://cameras.herdsense.com/horsewise/${currentCamera.hls_ip}/index.m3u8`
    : null;

  return (
    <div css={css.videocard}>
      <VideoPlayer
        url={hlsUrl}
        status={status}
        setStatus={setStatus}
        fullScreen={full_screen}
      />
      <p>{status}</p>
    </div>
  );
}
