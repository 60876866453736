import { posRelative } from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'

export const css = {
  main: {
    ...posRelative(),
    backgroundColor: appColors?.grays?.[0],
    paddingTop: 60,
  },
}
