/** @jsxImportSource @emotion/react */

import { Form, Formik } from 'formik'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { useContext } from 'react'

import { acceptInviteSchema } from '../../schemas'
import Client from '../../client'
import AuthContext from '../../context/auth-context'

import { LoginLogo } from '../../components/icons/logo-login'
import { Button, FormElement, Icon } from '../../components/'

import { css as tempCss } from './css'
import { appColors } from '../../emotion/appColors'

export default function AcceptInvite() {
  const navigate = useNavigate()
  const { login } = useContext(AuthContext)

  const [params] = useSearchParams()

  const theme = useTheme()

  const css = tempCss(theme)

  const token = params.get('token')
  const id = params.get('id')

  const initialValues = {
    id,
    token,
    password: '',
    confirmPassword: '',
  }

  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: 'accept-invite',
    mutationFn: async data => Client.post('/auth/accept-invite', data),
    onSuccess: values => {
      login(values)
      navigate('/')
    }
  })

  const intro = (
    <div css={css.formBlock.intro}>
      <h1>Accept Invitation</h1>
      <p>Create a password to accept your invitation.</p>
    </div>
  )

  const header = (
    <div css={css?.header}>
      <LoginLogo />
      {intro}
    </div>
  );

  const form = formik => (
    <div css={css?.formBlock}>
      <FormElement
        element="password"
        elementType="password"
        id="password"
        name="password"
        label="Password"
        value={formik.values.password}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />

      <FormElement
        element="password"
        elementType="password"
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm Password"
        value={formik.values.confirmPassword}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />

      <div css={css?.formBlock?.checklist}>
        <div css={css?.formBlock?.checklist?.check}>
          <Icon
            icon="CheckIcon"
            fill={formik.values.password.length >= 6 ?
              appColors?.greens?.secondary :
              appColors?.grays?.[600]
            }
          />
          <p css={css?.formBlock?.checklist?.msg(formik.values.password.length >= 6)}>
            Must be at least 6 characters
          </p>
        </div>
        <div css={css?.formBlock?.checklist?.check}>
          <Icon
            icon="CheckIcon"
            fill={formik.values.password.length >= 6 && formik?.isValid ?
              appColors?.greens?.secondary :
              appColors?.grays?.[600]
            }
          />
          <p css={css?.formBlock?.checklist?.msg(formik.values.password.length >= 6 && formik?.isValid)}>
            Passwords match
          </p>
        </div>
      </div>

      <Button
        type="submit"
        label="Accept"
        disabled={!formik.dirty || !formik.isValid || isLoading}
        onClick={formik.handleSubmit}
      />
    </div>
  )

  return (
    <div css={css}>
      <div css={css.block}>
        <Formik
          initialValues={initialValues}
          validationSchema={acceptInviteSchema}
          onSubmit={submitRequest}
        >
          {formik => (
            <Form>
              {header}
              {form(formik)}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
