import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentSelectedHorse: null
};

export const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    changeCurrentSelectedUser: (state, action) => {
      state.currentSelectedUser = action.payload
    },
  },
});

export const selectedCurrentUser = (state) => state.horse.currentSelectedUser;

export const { changeCurrentSelectedUser } = userListSlice.actions

export const userListReducer = userListSlice.reducer;