/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from "react";
import Button from "../../components/button/button";
import { Steps } from 'primereact/steps';
import { Formik, Form } from "formik";
import barnSetupSchema from "../../schemas/barnSetupSchema";
import Client from '../../client';
import { useMutation } from "../../hooks/use-mutation";
import FormTextInput from '../../components/form-text-input/form-text-input';
import EditableCellTable from '../../components/data-table/data-table';
import { LoginLogo } from "../../components/icons/logo-login";
import { css } from './css';
import { changeNumberOfStalls, changeTotalNumberOfStalls } from "../../store/barnSetupSlice";

const initialValues = {
  total_number_of_cameras: 0,
  total_number_of_stalls: 0,
};

export default function Onboarding() {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const firstStepItem = document.querySelector(".p-steps-item");
    if (firstStepItem) {
        if (activeIndex > 0) {
            firstStepItem.classList.add("highlight");
        } else {
            firstStepItem.classList.remove("highlight");
        }
    }
}, [activeIndex]);
  const [areas, setAreas] = useState([]);
  const [cameras, setCameras] = useState([]);

  const getColumns = () => {
    let columns = [
      { field: 'stall', header: 'Stall' },
    ];

    for (let i = 0; i < areas.length; i++) {
      const areaItem = areas[i];
      columns.push(
        {
          field: `area_${i}`,
          header: areaItem.name,
          isEditing: false
        }
      );
    }

    return columns;
  };

  const getAreas = async() => {
    let areasList = [];
    const response = await Client.get(`/areas`);
    if (response && response.data && response.data.length > 0) {
      const responseItems = response.data;
      for (let i = 0; i < responseItems.length; i++) {
        const item = responseItems[i];
        areasList.push(
          item
        );
      }
    }
    setAreas(areasList);
    return areasList;
  };

  useEffect(() => {
    getAreas();
  }, []);

  const items = [
    { label: 'Step 1' },
    { label: 'Step 2' },
  ];

  const [productsArray, setProductsArray] = useState([]);
  const [totalStalls, setTotalStalls] = useState(initialValues.total_number_of_stalls);
  const [headers, setHeaders] = useState([]);

  const stepperRef = useRef(null);
  const [hasError, setHasError] = useState(false);
  const { mutateAsync: submitRequest, isLoading } = useMutation({
    mutationKey: "add-horse",
    networkMode: "always",
    mutationFn: async (data) => Client.post("/horses", data),
    onSuccess: (values) => {}
  });

  const onNext = () => {
    setActiveIndex((prevIndex) => Math.min(prevIndex + 1, items.length - 1));
    // create a barn

  };

  const onBack = () => {
    setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  useEffect(() => {
    const newProductsArray = Array.from({ length: totalStalls }, (_, index) => ({
      stall: index + 1, // Set the stall number starting with 1
      right_rear_camera: '1',
      center_camera: '2',
      left_rear_camera: '3',
      code: `P${String(index + 1).padStart(3, '0')}`,
      name: `Product ${index + 1}`,
      quantity: 0,
      price: 0,
      isEditing: true
    }));
    setProductsArray(newProductsArray);
  }, [totalStalls]);

  const handleHeaderClick = (id) => {
    setHeaders(headers.map(header => 
      header.id === id ? { ...header, isEditing: true } : header
    ));
  };

  const handleHeaderChange = (id, text) => {
    setHeaders(headers.map(header => 
      header.id === id ? { ...header, text } : header
    ));
  };

  const handleHeaderBlur = (id) => {
    setHeaders(headers.map(header => 
      header.id === id ? { ...header, isEditing: false } : header
    ));
  };

  const handleProductDataChange = (updatedProductData) => {
    setProductsArray(updatedProductData);
  };

  const createStalls = async (numberOfStalls) => {
    for (let i = 1; i < numberOfStalls; i++) {
      let stallName = `Stall #${i}`;
      const data = {
        name: stallName
      };
      const response = await Client.post("/stalls", data);
    }
  };

  const createCameras = async (numberOfCameras) => {
    for (let i = 1; i < numberOfCameras; i++) {
      const data = {

      };
      const response = await Client.post("/cameras", data);
    }
  };

  const createBarn = async (totalNumberOfStalls, totalNumberOfCameras) => {
    try {
      const data = {
        number_of_stalls: totalNumberOfStalls,
        total_number_of_cameras: totalNumberOfCameras
      };
      const response = await Client.post("/barns", data);
      return response;
    }
    catch (exception) {
      console.error('Exception received..', exception);
    }
  };

  const columns = getColumns();

  const finishOnboarding = () => {
    console.log('finish onboarding..');
    console.log('products..', productsArray);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('on submit fired..');
          try {
            let totalNumberOfStalls;
            let totalNumberOfCameras;
            if ("number_of_stalls" in values) {
              changeNumberOfStalls(values["number_of_stalls"]);
              totalNumberOfStalls = values["number_of_stalls"];
            }
            if ("total_number_of_cameras" in values) {
              changeTotalNumberOfStalls(values["total_number_of_cameras"]);
              totalNumberOfCameras = values["total_number_of_cameras"];
            }

            // create a barn
            const response = await Client.post("/barns", {
              number_of_stalls: totalNumberOfStalls,
              total_number_of_cameras: totalNumberOfCameras
            });

            console.log('products..', productsArray);

            console.log("response is..", response);

          } catch (error) {
            console.error(error);
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={barnSetupSchema}
      >
        {formik => {
          // Directly update totalStalls state whenever formik values change
          if (formik.values.total_number_of_stalls !== totalStalls) {
            setTotalStalls(formik.values.total_number_of_stalls);
          }

          return (
            <Form>
              <div css={css.onboarding}>
                <LoginLogo />
                <div className="header">Barn setup</div>
                <div className="formgroup">
                  {activeIndex === 0 && (
                    <>
                    <div>
                    <p className="subheader">In a few easy steps we'll have you ready to go!</p>
                  </div>
                    <div className="stepstart">
                      <FormTextInput
                        formik={formik}
                        label="Number of stalls"
                        name="total_number_of_stalls"
                        labelPosition="top"
                      />
                      {formik.errors.number_of_stalls && (
                        <div css={css.feedback}>
                          {formik.errors.number_of_stalls}
                        </div>
                      )}
                      <FormTextInput
                        formik={formik}
                        label="Total number of cameras"
                        name="total_number_of_cameras"
                        labelPosition="top"
                      />
                      {formik.errors.total_number_of_cameras && (
                        <div css={css.feedback}>
                          {formik.errors.total_number_of_cameras}
                        </div>
                      )}
                    </div>
                    </>
                  )}
                  {activeIndex === 1 && (
                     <>
                     <div>
                     <p className="subheader">Assign cameras to stalls</p>
                   </div>
                    <div className="stepend">
                    <EditableCellTable
                      products={productsArray}
                      totalNumberOfCameras={formik.values.total_number_of_cameras}
                      headers={headers}
                      columns={columns}
                      onHeaderClick={handleHeaderClick}
                      onHeaderChange={handleHeaderChange}
                      onHeaderBlur={handleHeaderBlur}
                      onProductDataChange={handleProductDataChange}
                    />
                    </div>
                    </>
                  )}
                </div>

                <div >
                  <div>
                    {activeIndex > 0 && (
                      <div css={css.onboardingButtons}>
                        <div>
                          {/* <Button
                            label="Previous"
                            onClick={onBack}
                            disabled={activeIndex === 0}
                          /> */}
                        </div>
                        <div>
                          <Button
                            label="Finish"
                            onClick={finishOnboarding}
                            loading={isLoading}
                            type="submit"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div css={css.onboardingButtons}>
                    <div>
                      {activeIndex < items.length - 1 ? (
                        <Button label="Next" onClick={onNext} />
                      ) : null}
                    </div>{' '}
                  </div>
                  <Steps
                  css={css.steps}
                    model={items}
                    activeIndex={activeIndex}
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  );
}