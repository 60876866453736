import {
  borderRadius,
  fontbox,
  fullWidth,
  paddingX,
  paddingY,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  dialogHeader: {
    ...fontbox(appFonts.inter, 18, 700, 16),
  },
  container: {
    label: {
      ...fontbox(appFonts.inter, 14, 700, 24),
      color: appColors.grays[700],
      marginBottom: 8,
    },

    textarea: {
      ...borderRadius(4),
      ...fontbox(appFonts?.inter, 14, 400, 18),
      ...fullWidth(),
      ...paddingX(12),
      ...paddingY(10),
      backgroundColor: appColors?.grays?.[0],
      border: `1px solid ${appColors?.grays?.tabActive}`,
      color: appColors?.grays?.[900],
      minHeight: 80,
      minWidth: '48%',
      outline: 'none',
    },

    input: {
      ...fontbox(appFonts.inter, 14, 400, 24),
      ...borderRadius(4),
      border: '1px solid #E6E9EA',
      color: appColors.grays[900],
      width: '100%',
      padding: '12px 16px',
      marginBottom: 24,
      '&:focus-visible': {
        borderColor: appColors.grays[900],
      },
    },
    select: {
      ...fontbox(appFonts.inter, 14, 400, 24),
      ...borderRadius(4),
      border: '1px solid #E6E9EA',
      color: appColors.grays[900],
      width: '100%',
      padding: '12px 16px',
      marginBottom: 24,
      '&:focus-visible': {
        borderColor: appColors.grays[900],
      },
    },
  },
}
