/** @jsxImportSource @emotion/react */

import { InputTextarea as PRInputTextarea } from 'primereact/inputtextarea'

import { cssFormElements } from '../css'

const InputTextarea = data => {
  const getLabel = data?.label && <label>{data.label}</label>

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== 'data') {
        acc[key] = data[key]
      }
      return acc
    }, {}),
  }

  const getElement = <PRInputTextarea {...elProps} />

  return (
    <div css={cssFormElements}>
      {getLabel}
      {getElement}
    </div>
  )
}

export default InputTextarea
