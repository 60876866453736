import React from 'react'

import { Input, InputTextarea, Password } from './elements'

const FormElement = ({
  element,
  id,
  name,
  onBlur,
  onChange,
  value,
  label,
  placeholder,
}) => {
  const components = {
    'input': Input,
    'inputTextarea': InputTextarea,
    'password': Password,
  }

  const props = {
    id,
    name,
    onBlur,
    onChange,
    value,
    label,
    placeholder,
  }

  const getFormElement = type => {
    const Component = components[type]

    return <Component {...props} />
  }

  return <>{getFormElement(element)}</>
}

export default FormElement
