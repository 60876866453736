/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'

import { css as tempCss } from './css'

import Icon from '../icon/icon'

const NoDataScreen = props => {
  const {
    icon,
    msg = 'No data found.'
  } = props

  const theme = useTheme()

  const css = tempCss(theme)

  const getIcon = icon !== null ? <Icon icon={icon} fill={theme?.button?.pill?.color} /> : null

  return (
    <div className='noDataScreen' css={css}>
      {getIcon}
      <h2>{msg}</h2>
    </div>
  )
}

export default NoDataScreen
