/** @jsxImportSource @emotion/react */

import { Toast } from "primereact/toast"
import { ConfirmDialog as PRConfirmDialog } from "primereact/confirmdialog"
import { useRef } from "react"

const ConfirmDialog = (props) => {
  const {
    group = null,
    content = null,
  } = props

  const toast = useRef(null)

  return (
    <>
      <Toast ref={toast} />

      <PRConfirmDialog
        group={group}
        content={content}
      />
    </>
  )
}

export default ConfirmDialog
