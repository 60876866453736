/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { Droppable } from '../droppable'
import { Dropdown } from 'primereact/dropdown'

import Client from '../../client'

import PillButton from '../pill-button/pill-button'
import StallSettingsModal from '../modals/StallSettingsModal'

import { css } from './css';

export default function CameraSelectorComponent(props) {
  const {
    activeCamera,
    setActiveCamera,
    activeStall,
    refetch
  } = props
  // const [activeCameraId, setActiveCameraId] = useState(null)
  // const [cameras, setCameras] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const { cameraData } = props

  // const handleCogClick = () => {
  //   setIsModalOpen(true)
  // }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  
  const cameraOptions = activeStall?.cameras?.map((item, key) => {
    const name = (activeStall.default_camera_id || 0) === item.id ? "*" + item.name : item.name
    return {
      value: item.id,
      label: name
    }  
  })

  const setDefaultCamera = async (id) => {
    if(id !== activeStall.default_camera_id) {
      await Client.put(`/stall/${activeStall.id}`, {updated_stall: {default_camera_id:id}})
      refetch()
    }
  }
  const camerasList = activeStall?.cameras?.map((item, key) => (
      <PillButton
        key={key}
        onClick={() => setActiveCamera(item)}
        className={activeCamera?.id === item?.id ? 'active' : ''}
      >
        {(activeStall.default_camera_id || 0) === item.id ? "*" + item.name : item.name}
      </PillButton>
  ));

  // commented out the settings button
  // const settingsBtn = (
  //   <div css={css.settings} onClick={handleCogClick}>
  //     <Icon icon="Settings" stroke="#475467" />
  //   </div>
  // );

  const cameraArea = (
    <div css={css.cameraArea} className="camera-area">
      {camerasList}

      <div css={css.formEl}>
        <label>Default Camera</label>
        
        <Dropdown
          options={cameraOptions}
          label={"Default Camera"}
          style={{align:"right"}}
          placeholder={
            "Select a default"
          }
          onChange={(it) => {setDefaultCamera(it?.value)}}
          value={activeStall.default_camera_id}
        />
      </div>
      {/* {activeStall?.camera?.map((col, index) => (
        <Tag key={index} onClick={() => onClickTag(col.id)}>
          {col.name}
        </Tag>
      ))} */}
    </div>
  );

  const modals = (
    <>
      {isModalOpen && (
        <StallSettingsModal
          open={isModalOpen}
          setShowModal={setIsModalOpen}
          onClose={handleModalClose}
        />
      )}
    </>
  );

  return (
    
      <Droppable>
        {cameraArea}
        {modals}
      </Droppable>
    
  )
}
