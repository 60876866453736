import {
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  theme,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  main: {
    ...flexbox('column', 'center', 'flex-start'),
    ...fullHeight(),
    backgroundColor: theme.colors.bgSecondary,
    paddingTop: 65,
  },
  button: {
    ...fontbox(appFonts.inter, 14, 700, 24),
    ...fullWidth(),
    '&:hover': {
      color: appColors.grays[0],
    },
  },
  form: {
    ...fullWidth(),

    textarea: {
      marginBottom: 16,
    },
  },
}
