import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';

const EditableCellTable = ({ products, totalNumberOfCameras, headers, onHeaderClick, onHeaderChange, onHeaderBlur, columns, cameras }) => {
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        // Initialize productData with default camera values
        const initializedProducts = products.map((product) => {
            const updatedProduct = { ...product };
            for (let i = 1; i <= totalNumberOfCameras; i++) {
                updatedProduct[`camera_${i}`] = `Camera ${i}`;
            }
            return updatedProduct;
        });
        setProductData(initializedProducts);
    }, [products, totalNumberOfCameras]);

    const isPositiveInteger = (val) => {
        let str = String(val);

        str = str.trim();

        if (!str) {
            return false;
        }

        str = str.replace(/^0+/, '') || '0';
        let n = Math.floor(Number(str));

        return n !== Infinity && String(n) === str && n >= 0;
    };

    const onCellEditComplete = (e) => {

        let { rowData, newValue, field, originalEvent: event } = e;

        /*

        switch (field) {
            case 'quantity':
            case 'price':
                if (isPositiveInteger(newValue)) rowData[field] = newValue;
                else event.preventDefault();
                break;

            default:
                if (newValue.trim().length > 0) rowData[field] = newValue;
                else event.preventDefault();
                break;
        }

        */
    };

    const cellEditor = (options) => {
    if (options.field === 'price') return priceEditor(options);
    else if (options.field.startsWith('camera_')) return cameraEditor(options);
    else return textEditor(options);
};

    const cameraEditor = () => {
        let camerasList = [];

        for (let i = 0; i < cameras.length; i++) {
            const cameraItem = cameras[i];

            camerasList.push(
                {
                    name: cameraItem.name,
                    id: cameraItem.id
                }
            );
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Camera </span>
                <Dropdown placeholder="Select a camera" options={camerasList}
                />
            </div>
        );
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
    };

    const priceEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="USD" locale="en-US" onKeyDown={(e) => e.stopPropagation()} />;
    };

    const priceBodyTemplate = (rowData) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.price);
    };

    const cameraBodyTemplate = (rowData, field) => {
        return rowData[field];
    };

    // Generate columns based on totalNumberOfCameras

    // Calculate the width percentage for each column
    const columnWidth = `${100 / columns.length}%`;

    return (
        <div className="card p-fluid">
            <DataTable
                value={productData}
                editMode="cell"
                tableStyle={{ minWidth: '50rem' }}
            >
                {columns.map(({ field, header, isEditing }) => {
                    const isEditable = field !== 'stall';
                    const isCameraField = field.startsWith('camera_');
                    const bodyStyle = field === 'stall' ? { paddingLeft: '51px' } : {};

                    return (
                        <Column
                            key={field}
                            field={field}
                            header={
                                isEditing ? (
                                    <InputText
                                        value={header}
                                        onChange={(e) => onHeaderChange(field, e.target.value)}
                                        onBlur={() => onHeaderBlur(field)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                onHeaderBlur(field);
                                            }
                                        }}
                                    />
                                ) : (
                                    <span onClick={() => onHeaderClick(field)}>{header}</span>
                                )
                            }
                            style={{ width: columnWidth }}
                            bodyStyle={bodyStyle}
                            body={isCameraField ? (rowData) => cameraBodyTemplate(rowData, field) : (field === 'price' && priceBodyTemplate)}
                            editor={isEditable ? (options) => (isCameraField ? cameraEditor(options) : cellEditor(options)) : undefined}
                            onCellEditComplete={isEditable ? onCellEditComplete : undefined}
                        />
                    );
                })}
            </DataTable>
        </div>
    );
}

export default EditableCellTable;