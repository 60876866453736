import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import VideoComponent from '../../components/video-component/video-component';

import css from './styles.module.css';

export default function MobileStream() {
  const { cameraId } = useParams();

  const activeCamera = useMemo(() => ({ cameraId }), [cameraId]);

  // only need if options need to be passed to stream, see beef genius if necessary
  // const [searchParams] = useSearchParams();

  return (
    <div className={css.videoplayer}>
      <VideoComponent currentCamera={activeCamera} />
    </div>
  );
}
