import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentCamera: null
};

export const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    changeCurrentCamera: (state, action) => {
      state.currentCamera = action.payload
    },
  },
});

export const selectedCurrentCamera = (state) => state.camera.currentCamera;

export const { changeCurrentCamera } = cameraSlice.actions

export const cameraReducer = cameraSlice.reducer;