/** @jsxImportSource @emotion/react */

import { useEffect } from "react";
import moment from 'moment';

import { useAppSelector } from "../../shared/model";
import { selectedReloadState } from "../../store/noteSlice";
import { useQuery } from "../../hooks";

import { css } from "./css";

function Note(value) {

  const { data: userData } = useQuery(`/users/${value?.value?.userId}`);

  const formattedDate = moment(value?.value?.created_at).format("MM/DD/YY hh:mma");

  const roleName = userData?.is_admin ? "Admin" : userData?.is_vet ? "Vet" : "Guardian";

  const getName = userData?.first_name && userData?.last_name
    ? `${userData?.first_name} ${userData?.last_name}`
    : "Note Author";

  const noteHeader = (
    <div css={css.noteHeader}>
      <div css={css.noteHeader.authorInfo}>
        <span css={css.noteName}>
          {getName}
        </span> - {roleName}</div>
      <div css={css.noteHeader.date}>{formattedDate}</div>
    </div>
  );

  const noteContent = (
    <div css={css.noteContent}>
      {value?.value?.note_content}
    </div>
  );

  return (
    <div css={css.noteBlock} className="note-block">
      {noteHeader}
      {noteContent}
    </div>
  );
}

export default function NoteListingComponent(props) {
  const {
    notes
  } = props;

  const currentReloadState = useAppSelector(selectedReloadState);

  useEffect(() => {
    // console.log('current reload state..', currentReloadState);
  }, [currentReloadState]);

  return (
    <div css={css.noteArea}>
      {Object.entries(notes).map(([key, value]) => <Note key={key} value={value} />)}
    </div>
  );
}