/** @jsxImportSource @emotion/react */

import { useMemo } from 'react'
import { useTheme } from '@emotion/react'

import {
  Icon,
  Button
} from '../index'

import { css as tempCss } from './css'

export default function DragAndDrop({
  file,
  placeholder,
  onClick,
  onChange,
  readOnly,
  className,
}) {
  const theme = useTheme()
  const css = tempCss(theme)

  // Create a URL for the uploaded file
  const fileUrl = useMemo(() => {
    if (!file) return placeholder

    if (file instanceof File) {
      return URL.createObjectURL(file)
    }

    return file
  }, [file, placeholder])

  const dropHandler = e => {
    e.preventDefault()

    if (!e.dataTransfer.items) {
      return
    }

    Array.from(e.dataTransfer.items).forEach((item, i) => {
      if (item.kind !== 'file' || !onChange) {
        return
      }

      const file = item.getAsFile()
      onChange(file)
    })
  }

  // prevent browser's default drag behavior
  const dragOverHandler = ev => {
    ev.preventDefault()
  }

  // const link = formik.values.avatar?.link;
  // const link =  formik.values.avatar?.link ||
  //               formik.values.icon?.link ||
  //               formik.values.map?.link ||
  //               formik.values.eventMap?.link;

  const logo = (
    <div css={css.container}>
      <div
        css={css.dropzone}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onClick={onClick}
      >
        <div css={css?.dropzone?.icon}>
          <Icon icon="Upload" />
        </div>
        
        <div css={css?.direct}>
          <div css={css?.direct?.title}>
            <span>Click to upload</span> or drag and drop
            <br />
            PNG or JPG (max. 300x300px)
          </div>
        </div>
      </div>
    </div>
  )

  if (!file) {
    return logo
  }

  return (
    <div css={[css.container(fileUrl), css.hasImg]}>
      <div
        css={css.dropzone}
        onDrop={readOnly ? null : dropHandler}
        // onDragOver={dragOverHandler}
        onChange={onChange}
        onClick={onClick}
      >
        {!readOnly &&
          <Button onClick={e => { e.preventDefault() }}>
            Edit Image
          </Button>
        }
      </div>
    </div>
  )
}
