/** @jsxImportSource @emotion/react */

import {
  borderRadius,
  fontbox,
  fullWidth,
  paddingX,
  theme,
} from "../../emotion/utils";

const FormInput = ({
  formik,
  name,
  number,
  onInput,
  readOnly,
  style,
  testChange,
  value,
  placeholder,
  onBlur,
  onFocus,
  password,
  type = "text",
}) => {
  const errorClass =
    formik?.errors[name] && formik?.touched[name] ? "error" : "";

  const inputProps = {
    css: css.input,
    className: errorClass,
    style: style,
    // type: password ? "password" : number ? "number" : "text",
    type: type,
    pattern: number ? "[0-9]*" : null,
    id: name,
    readOnly,
    name,
    onBlur: (e) => {
      formik.handleBlur(e);
    },
    onChange: formik?.handleChange,
    onInput: onInput,
    value: value,
    placeholder: placeholder,
  };

  return <input {...inputProps} />;
};
const css = {
  input: {
      ...borderRadius(4),
      ...fontbox(theme.fonts.inter, 16, 400, 16 * 1.44),
      ...fullWidth(),
      ...paddingX(10),
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.lightGrey}`,
      color: theme.colors.nero,
      height: "40px!important",
  },
};
export default FormInput;