import { fontbox } from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  feedback: {
    ...fontbox(appFonts.inter, 12, 400, 12),
    color: appColors.reds.base,
    marginBottom: 8,
    marginTop: -20,
  },
  subheader: {
    ...fontbox(appFonts.inter, 16, 400, 24),
    color: appColors.grays[700],
    marginBottom: 8,
  },
}
