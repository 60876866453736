import { appFonts } from '../../emotion/appFonts'
import {
  borderRadius,
  flexbox,
  fontbox,
  fullHeight,
  fullWidth,
  paddingX,
  paddingXY,
  paddingY,
  posRelative,
  square,
} from '../../emotion/utils'

export const css = theme => ({
  container: fileUrl => ({
    ...borderRadius(4),
    ...fullWidth(),
    backgroundColor: theme?.colors?.white,
    backgroundImage: `url('${fileUrl}')`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    overflow: 'hidden',
  }),

  dropzone: {
    ...borderRadius(12),
    ...flexbox('column', 'center', 'center', 12),
    ...fullHeight(),
    ...paddingX(24),
    ...paddingY(16),
    ...posRelative(null, null, null, null, 1),
    backgroundColor: theme?.colors?.white,
    border: `1px solid ${theme?.button?.pill?.border}`,

    icon: {
      ...borderRadius(8),
      ...paddingXY(10),
      ...square(40),
      border: `1px solid ${theme?.button?.pill?.border}`,

      svg: {
        ...square(20),
      },
    },
  },

  direct: {
    ...flexbox('column', 'center', 'center', 5),
    color: theme?.colors?.darkBlue,

    title: {
      ...fontbox(appFonts?.inter, 14, 400, 20),
      color: theme?.colors?.darkBlue,

      'span': {
        fontWeight: 600,
      },
    },
  },

  hasImg: {
    height: 150,

    '> div': {
      backgroundColor: theme?.colors?.transparent,
    },
  },
})
