import {
  borderRadius,
  flexbox,
  fontbox,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  container: {
    ...flexbox('row', 'center', 'flex-start', 5),

    label: {
      ...fontbox(appFonts.inter, 14, 400, 24),
      color: appColors.grays[700],
    },

    '& .multiselect': {
      ...fontbox(appFonts.inter, 14, 400, 24),
      ...borderRadius(4),
      border: '1px solid #E6E9EA',
      width: '100%',

      '&:focus-visible': {
        borderColor: appColors.grays[900],
      },
    },
  },

  dialogHeader: {
    ...fontbox(appFonts.inter, 18, 700, 16),
  },
  
  groupLabel: {
    ...fontbox(appFonts.inter, 14, 700, 24),
    color: appColors.grays[700],
  },
}
