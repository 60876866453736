import { object, string } from 'yup';

const acceptInviteSchema = object({
  password: string().label("Password").min(6).required(),
  confirmPassword: string()
    .label("Confirm Password")
    .test(
      'passwords-match',
      ({ label }) => `Passwords must match`,
      (value, { parent }) => {
        return value === parent.password
      }
    )
});

export default acceptInviteSchema;