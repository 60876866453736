import {
  borderRadius,
	flexbox,
	fullWidth,
	objectContain,
	posRelative,
	square,
	theme,
} from "../../emotion/utils";

export const css = {
	container: (loading) => ({
		...fullWidth(),
    height: loading ? 215 : 'auto',

		"> div": {
      ...borderRadius(12),
			...flexbox("column", "center", "center", 0),
      ...posRelative(),
      ...square("inherit"),
			backgroundColor: loading ? theme.colors.white : `${theme.colors.textPrimary}25`,
			overflow: "hidden",

			canvas: {
        ...objectContain()
			}
		}
	}),

  loader: {
    ...flexbox("column", "center", "center", 0),
    minHeight: 175,

    img: {
      ...square(`70px!important`)
    }
  },
};
