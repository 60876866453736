import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";

import AuthContext from "../context/auth-context";

const LOGIN_PATH = "/login";

function ProtectedLayout() {
  const { userData } = useContext(AuthContext);

  if (!userData) {
    return <Navigate to={LOGIN_PATH} replace></Navigate>;
  }

  return <Outlet />;
}

export default ProtectedLayout;
