import { useContext } from 'react';
import { useMutation as reactQueryUseMutation } from '@tanstack/react-query';
import { useToastContext } from '../context/toast-context';
import AuthContext from '../context/auth-context';

export function useMutation(props) {
  const { runProtected } = useContext(AuthContext);

  const { isProtected = true } = props;
  const { showToast } = useToastContext();

  const query = reactQueryUseMutation({
    ...props,
    mutationFn: async (...args) => {
      let data = null;
      try {
        if (isProtected) {
          data = await runProtected(props.mutationFn, ...args);
        } else {
          data = await props.mutationFn(...args);
        }
      } catch (err) {
        if(props.onError)props.onError(err)
        else {
          showToast({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: 30000,
          });
        }
      }

      return data;
    },
  });

  return query;
}
