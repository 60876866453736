import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentSelectedHorse: null
};

export const horseListSlice = createSlice({
  name: 'horseList',
  initialState,
  reducers: {
    changeCurrentSelectedHorse: (state, action) => {
      state.currentSelectedHorse = action.payload
    },
  },
});

export const selectedCurrentHorse = (state) => state.horse.currentSelectedHorse;

export const { changeCurrentHorse } = horseListSlice.actions

export const horseListReducer = horseListSlice.reducer;