import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clipFile: null
};

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    changeClipFile: (state, action) => {
      state.clipFile = action.payload
    },
  },
});

export const selectedClipFile = (state) => state.viewer.clipFile;

export const { changeClipFile } = viewerSlice.actions;

export const viewerReducer = viewerSlice.reducer;