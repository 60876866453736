/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react'
import { css } from './css'
import { Button } from 'primereact/button'
import Client from '../../client'
import {
  changeCurrentHorse,
  selectedCurrentHorse,
} from '../../store/horseSlice'
import { useAppDispatch, useAppSelector } from '../../shared/model'

export default function RequestVetButton(props) {
  const { currentHorse, onClick = () => {} } = props

  // const requestVet = async () => {
  //   try {
  //     const response = await Client.post('/vets/request', {
  //       horse_id: currentHorse,
  //     })
  //     alert('The vet has been requested.')
  //   } catch (err) {
  //     alert('Error occured trying to request a vet, the email was not sent')
  //   }
  // }

  return (
    <>
      {/* <Button css={css.requestVetButton} onClick={requestVet}>
        Request Vet
      </Button> */}

      <Button css={css.requestVetButton} onClick={() => onClick(currentHorse)} disabled={!currentHorse?.vet_id}>
        Request Vet
      </Button>
    </>
  )
}
