/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  FormElement,
  FormSelectSimplified
} from "../../../components/";

import { css } from '../css'
import { useState } from "react";
import Client from "../../../client";
import { useMutation } from "../../../hooks";

export default function ModalRequestVet(props) {
  const {
    visible,
    close,
    horse
  } = props;

    const[vetNote, setVetNote] = useState(null)
  
    const { mutateAsync: onSubmit, isLoading } = useMutation({
      mutationKey: 'submit_vet_note',
      mutationFn: async data => Client.post('/vets/request', data),
      onSuccess: async (data) => {
        alert('Vet request submitted')
      },
      isProtected: false
    })
  const footer = (
    <div css={css.modal.footer}>
      <Button
        type='button'
        onClick={()=>close()}
        color='primary'
        variant='outlined'
      >Cancel</Button>

      <Button
        type='button'
        onClick={async()=>{await onSubmit({'note_content': vetNote, 'horse_id':horse?.id, 'vet_id':horse?.vet_id});close()}}
        color='primary'
      >Send</Button>
    </div>
  )

  const intro = true ?
    <p>Do you want to send a request to {horse?.vet_email}?</p>
    :
    <p>To which vet should we send the request?</p>

  const selectVet = true ? null : (
    <FormSelectSimplified
      name='vet'
      label=''
      labelPosition='top'
      required
      placeholder='Select vet'

      options={[
        { label: 'Vet 1', value: 'vet1' },
        { label: 'Vet 2', value: 'vet2' },
        { label: 'Vet 3', value: 'vet3' },
      ]}
      value=''
    />
  )

  return (
    <Dialog
      footer={footer}
      header="Send Vet Request?"
      onClick={()=>close()}
      size="sm"
      visible={visible}
    >
      <div css={css.modal.requestVet}>
        {intro}

        {selectVet}
   
        <FormElement
          element="inputTextarea"
          id="note"
          label=""
          name="note"
          onChange={(e)=>setVetNote(e.target.value)}
          placeholder="Add a note"
          value={vetNote}
        />
      </div>
    </Dialog>
  )
}
