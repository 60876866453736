/** @jsxImportSource @emotion/react */

import { InputText } from 'primereact/inputtext'

import { cssFormElements } from '../css'

const Input = data => {
  const getLabel = data?.label && <label>{data.label}</label>

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== 'data') {
        acc[key] = data[key]
      }
      return acc
    }, {}),
  }

  const getElement = <InputText {...elProps} />

  return (
    <div css={cssFormElements}>
      {getLabel}
      {getElement}
    </div>
  )
}

export default Input
