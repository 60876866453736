import { appColors } from './appColors'

export const appThemes = thm => {
  const theme = {
    light: {
      background: {
        primary: appColors.grays[0],
      },

      button: {
        primary: {
          background: appColors.greens.base,
          border: appColors.greens.base,
          color: appColors.grays[0],
        },

        danger: {
          background: appColors.reds.base,
          border: appColors.reds.base,
          color: appColors.grays[0],
        },
        
        pill: {
          background: appColors.blues.pill,
          border: appColors.blues.pillborder,
          color: appColors.grays[900],
        },

        inTableBtn: {
          background: appColors?.grays?.[510],
          border: appColors?.grays?.[510],
          color: appColors?.grays?.[510],
        },
      },

      text: {
        primary: appColors.grays[900],
        tertiary: appColors?.blues?.tertiary?.[600],
        danger: appColors.reds.base,
      },
    },

    dark: this?.light,
  }

  return theme[thm]
}

export const themes = {
  base: {
    grays: {
      0: '#FFFFFF',
      100: '#E6E6E6',
      200: '#CCCCCC',
      300: '#B3B3B3',
      400: '#999999',
      500: '#808080',
      600: '#666666',
      700: '#4D4D4D',
      800: '#333333',
      900: '#1A1A1A',
      1: '#000000',
    },

    blues: {
      base: '#202B72',
    },
  },

  light: {
    colors: {
      background: {
        primary: '#FFFFFF',
      },

      border: {
        primary: '#D0D5DD',
      },

      button: {
        primary: {
          background: '#3142AF',
          border: '#3142AF',
          color: '#FFFFFF',
        },
      },

      placeholder: '#667085',

      text: {
        primary: '#101828',
        secondary: '#344054',
        tertiary: '#475467',
      },
    },
  },

  dark: {
    colors: {
      background: {
        primary: '#202B72',
      },
    },
  },

  button: {
    primary: {
      background: '#3142AF',
      border: '#3142AF',
      color: '#FFFFFF',
    },
  },

  fonts: {
    inter: 'Inter',
    korolev: 'Korolev',
  },
}

export const theme = {
  colors: {
    transparent: 'transparent',

    // Grayscale
    white: '#FFFFFF',
    whitesmoke: '#F5F5F5',
    smoke: '#F8F8F8',
    lightGrey: '#D9D9D9',
    decorationGrey: '#D2D2D2',
    suvaGray: '#8C8C8C',
    darkGrey: '#555555',
    nero: '#1D1D1D',
    black: '#000000',

    // Palette
    primary: '#332048',
    secondary: '#4B9EFF',
    tertiary: '#D52C51',

    // Accents
    headerBg: '#202B72',
    darkBlue: '#151e5b',
  },

  fonts: {
    inter: 'Inter',
    korolev: 'Korolev',
  },
}

export const colors = {
  transparent: 'transparent',

  primary: '#332048',
  primary08: '#33204808',
  primary75: '#33204875',

  secondary: '#4B9EFF',
  secondary15: '#4B9EFF35',

  tertiary: '#8C8C8C',

  black: '#000000',

  handleInd: '#E1E1E1',
  whisper: '#EEEEEE',
  whitesmoke: '#F5F5F5',
  smoke: '#F8F8F8',
  white: '#FFFFFF',

  darkGrey: '#555555',
  grey: '#636363',
  semiGrey: '#B9B9B9',
  midGrey: '#A7A7A7',
  lightGrey: '#D9D9D9',
  gainsboroGray: '#DDDDDD',
  decorationGrey: '#D2D2D2',
  spanishGray: '#8F8F8F',

  red: '#D52C51',
  redDanish: '#BF3D36',

  blueDanish: '#3ABDEE',
  primaryBlue: '#202B72',

  grayGreen: '#CEE2DD',
}

export const fonts = {
  inter: 'Inter, sans-serif',
  korolev: 'KorolevBold, sans-serif',
}

export const bp = [576, 768, 992, 1200]

// FUNCTIONS ---------------------------------------------

export const darken = (color, percent) => {
  const f = parseInt(color?.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff

  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  )
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// MIXINS ------------------------------------------------

// Border Radius
export const borderRadius = rad => {
  const borderRadius = rad || 0

  return { borderRadius }
}
export const borderTopLeftRadius = rad => {
  const borderTopLeftRadius = rad || 0

  return { borderTopLeftRadius }
}
export const borderTopRightRadius = rad => {
  const borderTopRightRadius = rad || 0

  return { borderTopRightRadius }
}
export const borderBottomLeftRadius = rad => {
  const borderBottomLeftRadius = rad || 0

  return { borderBottomLeftRadius }
}
export const borderBottomRightRadius = rad => {
  const borderBottomRightRadius = rad || 0

  return { borderBottomRightRadius }
}

// Dimensions
export const dimensions = (w, h) => {
  const width = w || null
  const height = h || null

  return { width, height }
}

// Flexbox
export const flexbox = (fd, ai, jc, gp, fw) => {
  const alignItems = ai === undefined || ai === null ? 'center' : ai
  const display = 'flex'
  const flexDirection = fd === undefined || fd === 'column' ? 'column' : fd
  const gap = gp === undefined || gp === null ? 0 : gp
  const justifyContent = jc === undefined || jc === null ? 'center' : jc
  const flexWrap = fw === undefined || fw === null ? null : fw

  return {
    alignItems,
    display,
    flexDirection,
    flexWrap,
    gap,
    justifyContent,
  }
}

// Fontbox
export const fontbox = (ff, fs, fw, lh) => {
  const fontFamily = ff === undefined || ff === null ? fonts.inter : ff
  const fontSize = fs === undefined || fs === null ? 14 : fs
  const fontWeight = fw === undefined || fw === null ? '400' : `${fw}`
  const lineHeight = lh === undefined || lh === null ? `${fs + 2}px` : `${lh}px`

  return { fontFamily, fontSize, fontWeight, lineHeight }
}

// Full Height
export const fullHeight = () => {
  const height = '100%'

  return { height }
}

// Full Width
export const fullWidth = () => {
  const width = '100%'

  return { width }
}

// Margin
export const margin = (t, r, b, l) => {
  const marginTop = t || null
  const marginRight = r || null
  const marginBottom = b || null
  const marginLeft = l || null

  return { marginTop, marginRight, marginBottom, marginLeft }
}
export const marginY = num => {
  const marginTop = num !== undefined ? num : null
  const marginBottom = num !== undefined ? num : null

  return { marginTop, marginBottom }
}
export const marginX = num => {
  const marginRight = num !== undefined ? num : null
  const marginLeft = num !== undefined ? num : null

  return { marginRight, marginLeft }
}

// Margin Auto
export const marginAuto = () => {
  const margin = '0 auto'

  return { margin }
}

// Object Contain
export const objectContain = () => {
  const objectFit = 'contain'
  const maxHeight = '100%'
  const maxWidth = '100%'

  return { objectFit, maxHeight, maxWidth }
}

// Opaque Blur Background
export const opaqueBg = (bg, blur) => {
  const backgroundColor = `${bg}80` || 'rgba(255, 255, 255, 0.8)'
  const backdropFilter = `blur(${blur})`
  const WebkitBackdropFilter = `blur(${blur})`

  return { backgroundColor, backdropFilter, WebkitBackdropFilter }
}

// Padding
export const padding = (t, r, b, l) => {
  const paddingTop = t !== undefined ? t : null
  const paddingRight = r !== undefined ? r : null
  const paddingBottom = b !== undefined ? b : null
  const paddingLeft = l !== undefined ? l : null

  return { paddingTop, paddingRight, paddingBottom, paddingLeft }
}
export const paddingY = num => {
  const paddingTop = num !== undefined ? num : null
  const paddingBottom = num !== undefined ? num : null

  return { paddingTop, paddingBottom }
}
export const paddingX = num => {
  const paddingRight = num !== undefined ? num : null
  const paddingLeft = num !== undefined ? num : null

  return { paddingRight, paddingLeft }
}
export const paddingXY = num => {
  const padding = num !== undefined ? num : null

  return { padding }
}

export const position = (pos, topPos, rightPos, bottomPos, leftPos, z) => {
  const position = pos !== undefined ? pos : 'relative'
  const top = topPos !== undefined ? topPos : null
  const right = rightPos !== undefined ? rightPos : null
  const bottom = bottomPos !== undefined ? bottomPos : null
  const left = leftPos !== undefined ? leftPos : null
  const zIndex = z !== undefined ? z : null

  return { position, top, right, bottom, left, zIndex }
}
// Alias functions for position
export const posAbsolute = (topPos, rightPos, bottomPos, leftPos, z) => {
  return position('absolute', topPos, rightPos, bottomPos, leftPos, z)
}
export const posRelative = (topPos, rightPos, bottomPos, leftPos, z) => {
  return position('relative', topPos, rightPos, bottomPos, leftPos, z)
}
export const posFixed = (topPos, rightPos, bottomPos, leftPos, z) => {
  return position('fixed', topPos, rightPos, bottomPos, leftPos, z)
}
export const posSticky = (topPos, rightPos, bottomPos, leftPos, z) => {
  return position('sticky', topPos, rightPos, bottomPos, leftPos, z)
}
export const posStatic = () => {
  return position('static')
}

export const square = size => {
  const width = size ? size : null
  const height = size ? size : null

  return { width, height }
}

export const transition = (property = 'all', seconds = '0.3') => {
  const transition = `${property} ${seconds}s ease`

  return { transition }
}
