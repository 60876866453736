import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentClip: null
};

export const clipSlice = createSlice({
  name: 'clip',
  initialState,
  reducers: {
    changeCurrentClip: (state, action) => {
      state.currentClip = action.payload
    },
  },
});

export const selectedCurrentClip = (state) => state.camera.currentClip;

export const { changeCurrentClip } = clipSlice.actions

export const clipReducer = clipSlice.reducer;