import {
  borderRadius,
  darken,
  fontbox,
  paddingX,
  paddingY,
  transition,
} from '../../emotion/utils'

import { appColors } from '../../emotion/appColors'
import { appFonts } from '../../emotion/appFonts'

export const css = {
  requestVetButton: theme => ({
    ...borderRadius(8),
    ...fontbox(appFonts.inter, 14, 700, 14),
    ...paddingX(10),
    ...paddingY(14),
    ...transition(),
    justifyContent: 'center',
    width: '100%',
    backgroundColor: appColors.greens.secondary,
    border: `1px solid ${appColors.greens.secondary}`,
    color: appColors.grays[0],
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: darken(appColors.greens.secondary, -0.05),
    },

    '&.active': {
      backgroundColor: appColors.blues.pillactivebg,
      border: `1px solid ${appColors.blues.pillborderactive}`,
      color: appColors.blues.pillactive,
    },

    link: {
      ...borderRadius(0),
      ...paddingX(0),
      ...paddingY(0),
    },
  }),
}
